import React from "react"

import FormField from "../../../CommonComponents/FormField"

import "./ContactUsMain.scss"

export default function ContactUsMain({url,form}) {
  return (
    <div className="main-contactus-container">
      <div className="main-contactus-wrapper">
        <div className="main-form-container">
          <FormField
            header="contact us"
            nameLabel="Your Name *"
            emailLabel="Email *"
            numberLabel="Your Phone Number *"
            messageLabel="Message"
            url={url}
            form={form}
          />
        </div>
      </div>
    </div>
  )
}

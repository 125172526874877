import React from 'react';

import { Link } from 'gatsby';

import servicesDataDesktop from '../../../../information/MainPage/dataForItServicesMob';

import './ItServicesMob.scss';

const ItServicesMob = () => {
  return (
    <div className="mobservices-container">
      <div className="mobservices-center">
        <div className="mobservices-title">
          Dedicated IT <br /> services
        </div>
        <div className="mobservices-block">
          {servicesDataDesktop.map(item => {
            const { id, img, link, title1, title2 } = item
            return (
              <Link to={link} className="mobservices-item" key={id}>
                <div className="mobservices-icon">
                  <img className="mobservices-img" src={img} alt="Icon" />
                </div>
                <div className="mobservices-text">{title1}</div>
                <div className="mobservices-text">{title2}</div>
              </Link>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default ItServicesMob

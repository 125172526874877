import React from "react"

import { Link } from "gatsby"

import useMightyMouse from "react-hook-mighty-mouse"

import { MainLaptop, MainPhone } from "../../../images/MainPage/WeBuild"
import CursorWatcher from "../../../images/MainPage/WeBuild/cursor-watcher.png"
import Ampersand from "../../../images/MainPage/WeBuild/ampersand.png"
import BuildArrow from "../../../images/MainPage/WeBuild/BuildArrow.png"

import "./WeBuild.scss"

export default function WeBuild() {
  const {
    selectedElement: {
      position: { angle },
    },
  } = useMightyMouse(true, "cursor-watcher")

  return (
    <div className="build-center">
      <h2 className="build-title">We create</h2>
      <h3 className="build-subtitle">web and mobile applications</h3>
      <div className="build-block">
        <div className="build-item">
          <Link to="/cases/?platform=web/" className="build-image-holder">
            <MainLaptop />
          </Link>
          <div className="build-text">
            Take your online presence to the next level with experienced
            dedicated development services
          </div>
          <Link className="build-button" to="/cases/?platform=web/">
            <span className="bold">Web</span>development
            <img src={BuildArrow} alt='arrow' className='build-arrow' />
          </Link>
        </div>
        <div className="build-cursor-watcher">
          <div className="cursor-watcher-container">
            <img className="ampersand" src={Ampersand} alt="&" />
            <img
              className="circle"
              src={CursorWatcher}
              alt="Animated circle"
              id="cursor-watcher"
              style={{ transform: `rotate(${angle ? -angle - 40 : 0}deg)` }}
            />
          </div>
        </div>
        <div className="build-item">
          <Link to="/cases/?platform=mobile/" className="build-image-holder">
            <MainPhone />
          </Link>
          <div className="build-text">
            Power up your business with top-notch cross-platform mobile app
            development solutions
          </div>
          <Link className="build-button" to="/cases/?platform=mobile/">
            <span className="bold">Mobile</span>development
            <img src={BuildArrow} alt='arrow' className='build-arrow' />
          </Link>
        </div>
      </div>
      <div className="build-web" />
      <div className="build-mobile" />
    </div>
  )
}

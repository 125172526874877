import React from "react"

import Reviews from "./Reviews"
import ContactUsMain from "./ContactUsMain"

import "./RCHolder.scss"

const RCHolder = () => {
  const sendUrl = typeof window !== 'undefined' ? window?.location?.href : '';
  return (
    <div className="rcholder-container">
      <Reviews />
      <ContactUsMain form={0} url={sendUrl}/>
    </div>
  )
}

export default RCHolder

import React from "react"

export function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        height: "30px",
        width: "19px",
        zIndex: "500",
      }}
      onClick={onClick}
    />
  )
}

export function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        height: "30px",
        width: "19px",
        zIndex: "500",
      }}
      onClick={onClick}
    />
  )
}

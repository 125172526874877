import React from "react"
import ItServices from "./ItServices"
import HaveAnIdea from "./HaveAnIdea"
import Experience from "./Experience"
import ItServicesMob from "./ItServicesMob"
import ExperienceMob from "./ExperienceMob"

import "./BlockWrapper.scss"

class BlockWrapper extends React.Component {
  constructor() {
    super()

    this.state = { width: 0, height: 0 }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener("resize", this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }

  render() {
    const { width } = this.state
    return (
      <div className="blockwrapper-wrapper">
        <div className="blockwrapper-spot-main"></div>
        <div className="blockwrapper-rocket-main">
          <svg
            className="rocket-svg"
            width="656"
            height="672"
            viewBox="0 0 656 672"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="red-fire"
              d="M271.508 423.854C290.607 446.146 279.13 490.851 258.576 508.992C238.022 527.134 123.042 555.009 123.042 555.009C147.056 510.795 166.483 442.956 186.694 425.132C206.87 407.27 252.41 401.563 271.508 423.854Z"
              fill="#F74128"
            />
            <path
              className="red-fire2"
              d="M254.906 436.464C267.006 450.555 260.032 478.549 247.203 489.855C234.374 501.161 162.422 518.08 162.422 518.08C162.422 518.08 189.08 447.943 201.679 436.849C214.311 425.724 242.806 422.373 254.906 436.464Z"
              fill="#F74128"
            />
            <path
              className="orange-fire"
              d="M265.247 423.833C279.317 438.643 275.399 467.252 263.547 478.513C251.694 489.774 179.642 505.249 179.642 505.249C191.692 477.232 198.936 433.862 210.592 422.798C222.223 411.71 251.176 409.024 265.247 423.833Z"
              fill="#F77228"
            />
            <path
              className="yellow-fire"
              d="M249.876 437.06C256.887 444.426 255.065 458.523 249.244 464.044C243.423 469.565 207.917 476.917 207.917 476.917C207.917 476.917 217.148 441.755 222.866 436.337C228.598 430.905 242.865 429.694 249.876 437.06Z"
              fill="#F8CE3A"
            />
            <path
              d="M363.27 391.789C363.27 391.789 370.885 419.757 361.644 446.542C349.939 480.572 317.856 518.52 311.301 516.401C304.747 514.282 316.328 492.203 317.462 473.574C319.334 442.772 305.855 431.265 305.855 431.265L363.27 391.789Z"
              fill="#2346C3"
            />
            <path
              d="M363.27 391.789C363.27 391.789 370.885 419.757 361.644 446.542C349.939 480.572 317.856 518.52 311.301 516.401C304.747 514.282 316.328 492.203 317.462 473.574C319.334 442.772 305.855 431.265 305.855 431.265L363.27 391.789Z"
              fill="url(#paint0_linear_bw)"
              fillOpacity="0.3"
            />
            <path
              d="M363.27 391.789C363.27 391.789 370.885 419.758 361.644 446.543C349.939 480.573 317.856 518.521 311.301 516.402C304.747 514.282 316.328 492.204 317.462 473.575C319.334 442.773 305.855 431.265 305.855 431.265L363.27 391.789Z"
              fill="url(#paint1_linear_bw)"
            />
            <path
              d="M296.074 324.59C296.074 324.59 268.105 316.976 241.32 326.216C207.29 337.921 169.342 370.004 171.461 376.559C173.58 383.114 195.659 371.532 214.288 370.399C245.09 368.526 256.598 382.005 256.598 382.005L296.074 324.59Z"
              fill="#698CFF"
            />
            <path
              d="M296.074 324.59C296.074 324.59 268.105 316.976 241.32 326.216C207.29 337.921 169.342 370.004 171.461 376.559C173.58 383.114 195.659 371.532 214.288 370.399C245.09 368.526 256.598 382.005 256.598 382.005L296.074 324.59Z"
              fill="url(#paint2_linear_bw)"
              fillOpacity="0.5"
            />
            <path
              d="M296.074 324.589C296.074 324.589 268.106 316.975 241.32 326.215C207.29 337.92 169.342 370.004 171.461 376.558C173.581 383.113 195.66 371.531 214.289 370.398C245.091 368.525 256.598 382.004 256.598 382.004L296.074 324.589Z"
              fill="url(#paint3_linear_bw)"
            />
            <path
              d="M226.731 408.964C230.92 400.783 261.057 343.885 315.096 289.846C359.796 245.146 410.977 245.614 439.143 248.694C442.223 276.86 442.691 328.041 397.991 372.741C343.927 426.805 287.029 456.941 278.873 461.106L226.731 408.964Z"
              fill="#222222"
            />
            <path
              d="M226.731 408.964C230.92 400.783 261.057 343.885 315.096 289.846C359.796 245.146 410.977 245.614 439.143 248.694C442.223 276.86 442.691 328.041 397.991 372.741C343.927 426.805 287.029 456.941 278.873 461.106L226.731 408.964Z"
              fill="url(#paint4_linear_bw)"
            />
            <g filter="url(#filter0_i_bw)">
              <path
                d="M433.574 359.236C365.145 427.666 307.532 451.372 297.207 456.67L231.217 390.679C236.515 380.354 260.196 322.767 328.65 254.312C358.812 224.151 396.513 205.029 430.519 194.334L493.552 257.368C482.858 291.373 463.736 329.075 433.574 359.236Z"
                fill="#375AD7"
              />
            </g>
            <path
              d="M503.951 183.886C505.775 200.544 502.892 227.527 493.528 257.343L430.495 194.31C460.311 184.946 487.318 182.038 503.951 183.886Z"
              fill="#2346C3"
            />
            <g filter="url(#filter1_i_bw)">
              <path
                d="M430.494 194.309L462.011 225.826L264.187 423.65L231.192 390.655C236.49 380.33 260.171 322.742 328.626 254.287C358.787 224.126 396.464 204.979 430.494 194.309Z"
                fill="#7396FF"
              />
            </g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M376.584 314.325C367.674 306.146 355.336 306.277 347.037 313.897C338.738 321.515 338.595 332.842 347.505 341.021C356.414 349.2 368.751 349.068 377.05 341.45C385.349 333.831 385.493 322.505 376.584 314.325ZM336.614 351.011C322.679 338.219 323.119 318.876 337.789 305.408C352.395 291.999 373.465 291.474 387.466 304.328C401.466 317.181 400.895 336.524 386.289 349.932C371.619 363.4 350.548 363.803 336.614 351.011ZM409.267 314.022L408.116 311.26C407.158 308.961 408.452 306.381 410.996 305.638C416.478 304.037 422.133 301.042 426.436 297.093C432.809 291.242 433.254 286.897 430.372 284.251C421.597 276.195 400.606 306.535 383.19 290.546C375.216 283.226 374.959 271.288 388.17 259.16C392.83 254.882 398.468 251.348 404.143 249.363C406.489 248.541 409.137 249.64 410.129 251.776L411.381 254.473C412.414 256.701 411.272 259.269 408.834 260.151C404.041 261.886 399.972 264.315 396.811 267.216C390.439 273.066 390.394 277.536 393.342 280.242C401.983 288.175 423.04 257.897 440.258 273.703C448.095 280.898 448.354 292.959 435.076 305.148C429.101 310.634 421.629 314.824 415.105 316.654C412.72 317.324 410.165 316.174 409.267 314.022Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M412.294 274.729L391.976 295.047C399.62 296.341 407.44 291.888 414.257 288.007C420.98 284.179 426.729 280.906 430.372 284.25C433.253 286.896 432.809 291.241 426.435 297.092C422.133 301.042 416.478 304.036 410.996 305.637C408.452 306.38 407.158 308.96 408.116 311.259L409.267 314.021C410.165 316.173 412.72 317.323 415.105 316.653C421.628 314.823 429.1 310.633 435.076 305.147C448.354 292.958 448.094 280.897 440.258 273.702C431.21 265.397 421.103 269.816 412.294 274.729ZM384.868 302.154C385.758 302.83 386.625 303.554 387.466 304.326C401.467 317.18 400.895 336.523 386.289 349.931C371.619 363.399 350.548 363.803 336.614 351.01C336.509 350.914 336.405 350.818 336.302 350.721L346.737 340.285C346.985 340.533 347.241 340.778 347.505 341.02C356.414 349.199 368.751 349.067 377.05 341.449C385.349 333.83 385.493 322.504 376.584 314.325C375.893 313.69 375.181 313.106 374.452 312.571L384.868 302.154Z"
              fill="#CDCDCD"
            />
            <path
              d="M321.453 366.087L321.477 366.112C325.938 370.572 321.995 380.428 312.754 389.669C303.489 398.934 247.503 452.949 241.072 446.517C241.072 446.517 241.072 446.517 241.047 446.493C241.023 446.468 241.047 446.493 241.023 446.468C234.591 440.036 288.63 384.026 297.871 374.786C307.136 365.57 317.017 361.652 321.453 366.087Z"
              fill="#2346C3"
            />
            <path
              d="M462.01 225.826L430.494 194.309C460.309 184.946 487.316 182.038 503.949 183.886L462.01 225.826Z"
              fill="#698CFF"
            />
            <path
              d="M493.528 257.344C502.892 227.527 505.775 200.545 503.952 183.887C487.319 182.039 460.311 184.947 430.495 194.311C396.464 204.981 358.787 224.127 328.626 254.289C260.171 322.743 236.49 380.331 231.192 390.656L297.208 456.67C298.176 456.174 299.561 455.515 301.331 454.672C350.878 431.859 395.173 397.639 433.575 359.237C463.736 329.076 482.858 291.374 493.553 257.368L493.528 257.344Z"
              fill="url(#paint5_linear_bw)"
            />
            <path
              d="M321.454 366.087L241.048 446.542C241.048 446.542 241.048 446.542 241.023 446.517C234.592 440.086 288.631 384.076 297.872 374.835C307.137 365.57 317.018 361.652 321.454 366.087Z"
              fill="#698CFF"
            />
            <defs>
              <filter
                id="filter0_i_bw"
                x="201.75"
                y="163.389"
                width="312.748"
                height="312.748"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="-10" dy="-10" />
                <feGaussianBlur stdDeviation="17.5" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow"
                />
              </filter>
              <filter
                id="filter1_i_bw"
                x="211.725"
                y="173.364"
                width="260.286"
                height="260.286"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="10" dy="10" />
                <feGaussianBlur stdDeviation="17.5" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow"
                />
              </filter>
              <linearGradient
                id="paint0_linear_bw"
                x1="355.883"
                y1="414.524"
                x2="329.883"
                y2="481.524"
                gradientUnits="userSpaceOnUse"
              >
                <stop />
                <stop offset="0.700049" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_bw"
                x1="337.384"
                y1="473.525"
                x2="285.384"
                y2="473.525"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F8CE3A" stopOpacity="0" />
                <stop offset="1" stopColor="#F8CE3A" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_bw"
                x1="165.883"
                y1="374.524"
                x2="248.883"
                y2="350.524"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="white" />
                <stop offset="1" stopColor="white" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint3_linear_bw"
                x1="219.384"
                y1="354.025"
                x2="227.884"
                y2="402.025"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F8CE3A" stopOpacity="0" />
                <stop offset="1" stopColor="#F8CE3A" />
              </linearGradient>
              <linearGradient
                id="paint4_linear_bw"
                x1="238.383"
                y1="446.524"
                x2="265.883"
                y2="419.024"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F8CE3A" />
                <stop offset="1" stopColor="#1E1B0F" />
              </linearGradient>
              <linearGradient
                id="paint5_linear_bw"
                x1="663.884"
                y1="120.524"
                x2="332.384"
                y2="269.025"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#00284D" />
                <stop offset="1" stopColor="#002242" stopOpacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="blockwrapper-container">
          <div className="blockwrapper-pocket"></div>

          {width <= 690 ? <ItServicesMob /> : <ItServices />}

          <HaveAnIdea width={width} />

          {width <= 690 ? <ExperienceMob /> : <Experience />}
        </div>
      </div>
    )
  }
}

export default BlockWrapper

import {
  SocialApp,
  CRMSap,
  Cartovera,
  CityCouncil,
  RemoteAssistance,
  VideoConference,
  Wikigrads,
  CarpathianSeagul,
  IoT,
  RZD,
  Diasoft,
  GudJob,
  Chernomorsk,
  VideoConferenceTool,
  Dynamo,
  IllichRealty,
  ServiceDoc,
  TwoCents,
  UtilityManag,
  MCIngo,
  PickUpSport,
} from "../../images/CaseCards"

const dataForCases = [
  {
    id: 21,
    image: PickUpSport,
    title: "Sport Activities Mobile App",
    link: "/cases/pickup-sport/",
    classes: "pickupsport-card",
    tags: ["mobile"],
    tagBadges: [
      {
        id: "2",
        text: "mobile",
      },
    ],

    description:
      'Developing a mobile app for children and their parents that engages them in active recreation.',
  },
  {
    id: 20,
    image: MCIngo,
    title: "Medical center Application",
    link: "/cases/ingo/",
    classes: "ingo-card",
    tags: ["mobile"],
    tagBadges: [
      {
        id: "2",
        text: "mobile",
      },
    ],

    description:
      'Working application for making an appointment with the doctor for the medical center located in the capital of Ukraine.',
  },
  {
    id: 19,
    image: UtilityManag,
    title: "Utility Management Software",
    link: "/cases/utility-management-software/",
    tags: ["web", "mobile"],
    tagBadges: [
      {
        id: "1",
        text: "web",
      },
      {
        id: "2",
        text: "mobile",
      },
    ],

    description:
      'A business case of developing software for utility companies: "How to create a comprehensive utility management solution that has reduced the time from receiving meter readings to paying the bill to a few seconds".',
  },
  {
    id: 1,
    image: SocialApp,
    carouselImage: "Social App",
    title: "Social App",
    link: "/cases/social-app/",
    tags: ["mobile"],
    tagBadges: [
      {
        id: 1,
        text: "mobile",
      },
    ],
    description:
      "This application helps organize events with your friends in just 3 clicks. It is intended for youngsters to be able to control sending invitations and attendance.",
  },
  {
    id: 2,
    image: CRMSap,
    carouselImage: "CRMSAP Integration",
    title: "CRM/SAP Integration",
    link: "/cases/crm-sap-integration/",
    tags: ["web"],
    tagBadges: [
      {
        id: "1",
        text: "web",
      },
    ],
    description:
      "A web application with a high level of integration with SAP Business One that allows automated booking of IP-calling or any other products.",
  },
  {
    id: 3,
    image: Cartovera,
    carouselImage: "Cartovera",
    title: "Cartovera",
    link: "/cases/cartovera/",
    tags: ["web"],
    tagBadges: [
      {
        id: "1",
        text: "web",
      },
    ],
    description:
      "Private portal aimed at facilitating scientific research and collaboration between the analysts and researchers.",
  },
  {
    id: 18,
    image: TwoCents,
    title: "2cents",
    link: "/cases/2cents/",
    tags: ["web", "mobile"],
    tagBadges: [
      {
        id: "1",
        text: "web",
      },
      {
        id: "2",
        text: "mobile",
      },
    ],
    description:
      "A microblogging platform that respects the dignity of its community.",
  },
  {
    id: 17,
    image: ServiceDoc,
    carouselImage: "Service Doc",
    title: "Service Doc",
    link: "/cases/service-doc/",
    tags: ["web"],
    tagBadges: [
      {
        id: "1",
        text: "web",
      },
    ],
    description:
      "Servicedoc - on-line service for processing of documents. It allows to receive a complete document (contract, application, labor code etc.) without leaving your home in a few minutes.",
  },
  {
    id: 4,
    image: CityCouncil,
    carouselImage: "City Council Portal",
    title: "City Council Portal",
    link: "/cases/city-portal/",
    tags: ["web"],
    tagBadges: [
      {
        id: "1",
        text: "web",
      },
    ],
    description:
      "A government project that monitors latest developments in the city and provides access to public services for citizens and tourists.",
  },
  {
    id: 5,
    image: RemoteAssistance,
    carouselImage: "Remote Assistance",
    title: "RemoteAssistance",
    link: "/cases/webrtc/",
    tags: ["mobile", "webRTC"],
    tagBadges: [
      {
        id: "1",
        text: "mobile",
      },
      {
        id: "2",
        text: "WebRTC",
      },
    ],
    description:
      "The purpose of App is to facilitate communication between technicians and users, and with the help of such an application it will be possible to carry out repairs remotely.",
  },
  {
    id: 6,
    image: VideoConference,
    carouselImage: "Video Conference App",
    title: "Video Conference App",
    link: "/cases/video-conference/",
    tags: ["web", "webRTC"],
    tagBadges: [
      {
        id: "1",
        text: "web",
      },
      {
        id: "2",
        text: "WebRTC",
      },
    ],
    description:
      "Turnkey video conferencing system  for a web browser participation in a video conferences with internal and external users.",
  },
  {
    id: 7,
    image: Wikigrads,
    carouselImage: "Wikigrads",
    title: "Wikigrads",
    link: "/cases/wikigrads/",
    tags: ["web"],
    tagBadges: [
      {
        id: "1",
        text: "web",
      },
    ],
    description:
      "Free social learning platform enabling students in higher education to learn, collaborate and engage with each other more effectively.",
  },
  {
    id: 8,
    image: CarpathianSeagul,
    carouselImage: "Carpathian Seagull",
    title: "Carpathian Seagull",
    link: "/cases/karpatska-chaika/",
    tags: ["web", "desktop"],
    tagBadges: [
      {
        id: "1",
        text: "web",
      },
      {
        id: "2",
        text: "desktop",
      },
    ],
    description:
      "Modernization and automation of the ski resort management system.",
  },
  {
    id: 9,
    image: IoT,
    carouselImage: "Internet of Things",
    title: "Internet of Things",
    link: "/cases/iot/",
    tags: ["web"],
    tagBadges: [
      {
        id: "1",
        text: "web",
      },
    ],
    description:
      "The application allows comparing ongoing equipment performance to its standard performance to identify anomalies.",
  },
  {
    id: 12,
    image: GudJob,
    carouselImage: "GudJob",
    title: "GudJob",
    link: "/cases/gud-job/",
    tags: ["web", "mobile"],
    tagBadges: [
      {
        id: "1",
        text: "web",
      },
      {
        id: "2",
        text: "mobile",
      },
    ],
    description:
      "A strategy game that aims to assess hard skills of job candidates applying for managerial positions. Users compete with their virtual companies on the global market.",
  },
  {
    id: 13,
    image: Chernomorsk,
    carouselImage: "Chernomorsk",
    title: "Chernomorsk",
    link: "/cases/chernomorsk/",
    tags: ["web"],
    tagBadges: [
      {
        id: "1",
        text: "web",
      },
    ],
    description:
      "News, rental and events platform for local population and tourists which unites for a comfortable life and productive business.",
  },
  {
    id: 14,
    image: VideoConferenceTool,
    carouselImage: "Video-conferencing Tool",
    title: "Video-conferencing Tool",
    link: "/cases/videoconftool/",
    tags: ["web", "webRTC"],
    tagBadges: [
      {
        id: "1",
        text: "web",
      },
      {
        id: "2",
        text: "WebRTC",
      },
    ],
    description:
      "Web-RTC-based video-conferencing mobile application with advanced face detection algorithms allowing to add face filters, masks and other innovative features.",
  },
  {
    id: 15,
    image: Dynamo,
    carouselImage: "Football Club Fan Application",
    title: "Football Club Fun Application",
    link: "/cases/fcteam/",
    tags: ["mobile"],
    tagBadges: [
      {
        id: "1",
        text: "mobile",
      },
    ],
    description:
      "Social mobile app was designed for fans of a particular football team, where they can follow all the news of their favourite players and do lots of other things.",
  },
  {
    id: 16,
    image: IllichRealty,
    carouselImage: "IllichRealty",
    title: "IllichRealty",
    link: "/cases/ilich-realty/",
    tags: ["mobile"],
    tagBadges: [
      {
        id: "1",
        text: "mobile",
      },
    ],
    description:
      "The app was created to simplify real estate rent and sale in Odessa. The idea behind the app was to capitalize on the growing popularity of Odessa as a tourist destination.",
  },
  {
    id: 19,
    image: RZD,
    carouselImage: "Russian Railways",
    title: "Railways",
    link: "/cases/RZD-roads/",
    tags: ["web"],
    tagBadges: [
      {
        id: "1",
        text: "web",
      },
    ],
    description:
      "A fully automated control system for railway transportation, including automatic problem-solving of rolling-stock-related dispatching issues.",
  },
  {
    id: 20,
    image: Diasoft,
    carouselImage: "Diasoft",
    title: "Diasoft",
    link: "/cases/diasoft/",
    tags: ["web"],
    tagBadges: [
      {
        id: "1",
        text: "web",
      },
    ],
    description:
      "Complex system targeted for business processes automation at financial institutions, such as banks management and investment companies, open-end funds, etc..",
  },
]

const getCarouselData = () => {
  return dataForCases.map((item) => {
    const newObj = {...item};
    newObj.rawImage = item.image;
    newObj.image = null;
    if (item.carouselImage) {
      newObj.image = item.carouselImage;
    }
    newObj.info = item.description;
    newObj.alt = item.title;
    newObj.tags.unshift('all');
    newObj.tagBadges = item.tagBadges.map((tagBadge) => (
      {
        id: tagBadge.id,
        text: `#${tagBadge.text}`
      }
    ))
    return newObj;
  });
}

export { dataForCases, getCarouselData }

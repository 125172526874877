import React from "react"

export default function MainLaptop() {
  return (
    <svg
      className="laptop-svg"
      width="382"
      height="469"
      viewBox="0 0 396 469"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="laptop-border"
        d="M159.17 468.121C160.993 468.121 162.815 467.554 164.141 466.609L385.975 320.499C387.466 319.554 388.46 318.042 388.46 316.719C388.46 315.396 387.797 314.451 386.638 313.506L254.266 226.369C251.947 224.857 247.97 225.046 245.32 226.747L23.4853 372.857C21.9943 373.802 21.0002 375.314 21.0002 376.637C21.0002 377.96 21.6629 378.905 22.8226 379.85L155.194 466.987C156.188 467.932 157.679 468.121 159.17 468.121ZM385.312 318.987L163.478 465.096C161.324 466.609 157.845 466.609 156.023 465.474L23.651 378.338C23.154 377.96 22.657 377.582 22.657 376.826C22.657 376.07 23.3196 375.125 24.3137 374.558L246.148 228.448C248.302 226.936 251.781 226.936 253.603 228.07L385.975 315.207C386.472 315.585 386.969 315.963 386.969 316.719C386.803 317.475 386.306 318.42 385.312 318.987Z"
        fill="#EFEFEF"
      />
      <path
        d="M395.593 279.416L383.334 286.788L260.571 205.889C258.583 204.566 254.938 204.755 252.619 206.267L41.056 345.572C39.068 344.249 29.7903 339.145 29.7903 339.145L34.2635 347.462C34.4291 348.029 34.9261 348.596 35.5888 348.974L164.813 434.031C166.801 435.354 170.446 435.165 172.765 433.653L389.298 290.946C390.292 290.379 390.789 289.623 391.12 288.678L395.593 279.416Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M393.77 281.684L171.936 427.794C169.451 429.306 165.806 429.495 163.652 428.172L31.2806 341.035C29.1269 339.712 29.4583 337.255 31.9434 335.554L253.612 189.444C256.097 187.932 259.742 187.743 261.896 189.066L394.267 276.203C396.421 277.715 396.09 280.172 393.77 281.684Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M393.77 280.55L171.936 426.659C169.451 428.172 165.806 428.361 163.652 427.037L31.2804 339.901C30.6177 339.334 30.1207 338.767 29.955 338.2C29.7893 339.334 30.1207 340.279 31.2804 341.035L163.652 428.172C165.806 429.495 169.451 429.306 171.936 427.794L393.77 281.684C395.427 280.739 395.924 279.227 395.758 278.093C395.427 279.038 394.764 279.794 393.77 280.55Z"
        fill="white"
      />
      <path
        d="M252.729 178.614L238.73 11.7447L241.049 10.6106L237.736 8.34238C237.073 7.77533 236.079 7.96435 235.085 8.5314L16.15 149.163C14.3276 150.297 12.8366 153.51 13.168 155.589L22.6807 333.456C22.8464 334.401 23.3434 335.157 23.8404 335.346L27.1538 337.614L27.6509 334.023H27.4852L249.747 185.041C251.569 183.907 253.06 180.883 252.729 178.614Z"
        fill="url(#paint2_linear)"
      />
      <path
        d="M17.1855 153.708L13.5407 151.817C13.2094 152.951 12.8781 154.086 13.0437 155.031L23.4947 332.72C23.6604 333.666 24.1575 334.422 24.6545 334.611L27.9679 336.879L28.465 333.288H28.2993L39.5649 325.916L17.1855 153.708Z"
        fill="url(#paint3_linear)"
      />
      <path
        d="M254.606 188.879L30.4526 336.501C28.7959 337.635 27.3049 337.068 26.9736 334.989L16.0941 158.306C15.7628 156.038 17.0882 153.013 19.0763 151.879L238.398 10.9717C240.055 9.83761 241.546 10.4047 241.877 12.4838L257.588 182.264C257.92 184.532 256.594 187.556 254.606 188.879Z"
        fill="white"
      />
      <g filter="url(#filter0_di)">
        <path
          d="M245.569 17.8841L20.4221 155.832L29.5483 325.976L254.525 188.213L245.569 17.8841Z"
          fill="#BDE3FF"
        />
      </g>
      <path
        d="M46.4258 154.389C46.9226 154.404 47.5905 154.234 48.0981 153.87C49.7868 152.784 50.8511 150.357 50.5742 148.458C50.4358 147.508 49.9607 146.738 49.3092 146.341C48.492 145.94 47.6586 146.105 46.6487 146.643C44.96 147.729 43.8957 150.156 44.1726 152.055C44.311 153.005 44.7861 153.775 45.4376 154.172C45.7634 154.37 46.0946 154.38 46.4258 154.389ZM48.1282 147.064C48.2938 147.068 48.625 147.078 48.7852 147.272C49.2766 147.475 49.5915 148.051 49.7408 148.623C50.023 150.333 49.1406 152.198 47.623 153.1C46.9498 153.459 46.2819 153.629 45.6304 153.232C45.139 153.029 44.8241 152.452 44.6748 151.881C44.3926 150.171 45.2749 148.305 46.7925 147.403C47.2947 147.229 47.797 147.054 48.1282 147.064Z"
        fill="#375AD7"
      />
      <path
        d="M37.3135 159.988C37.8103 160.002 38.4781 159.832 38.9858 159.469C40.6744 158.383 41.7388 155.955 41.4619 154.056C41.3235 153.107 40.8484 152.337 40.1969 151.94C39.3797 151.538 38.5463 151.703 37.5363 152.241C35.8477 153.327 34.7834 155.755 35.0603 157.654C35.1987 158.603 35.6738 159.374 36.3253 159.77C36.6511 159.969 36.9822 159.978 37.3135 159.988ZM39.0159 152.662C39.1815 152.667 39.5127 152.677 39.6729 152.87C40.1643 153.073 40.4792 153.65 40.6285 154.221C40.9107 155.931 40.0283 157.797 38.5107 158.699C37.8375 159.058 37.1696 159.227 36.5181 158.831C36.0267 158.627 35.7118 158.051 35.5625 157.479C35.2803 155.769 36.1626 153.904 37.6802 153.002C38.1824 152.827 38.6847 152.653 39.0159 152.662Z"
        fill="#375AD7"
      />
      <path
        d="M26.1176 163.258C26.3999 164.968 27.8687 165.766 29.5573 164.68C31.2459 163.594 32.1337 161.54 31.8569 159.641C31.5746 157.931 30.106 157.132 28.4173 158.218C26.8943 159.309 25.8354 161.548 26.1176 163.258Z"
        fill="#375AD7"
      />
      <g filter="url(#filter1_d)">
        <path
          d="M65.1966 150.635C65.1201 149.342 64.1814 148.872 63.1 149.584L27.8545 172.812C26.7731 173.524 25.9584 175.15 26.0349 176.443L34.3166 316.432C34.3931 317.725 35.3318 318.196 36.4132 317.483L71.6587 294.255C72.7401 293.543 73.5548 291.917 73.4783 290.624L65.1966 150.635Z"
          fill="#7396FF"
        />
      </g>
      <path
        d="M236.557 43.1904C236.48 41.8798 232.795 43.204 228.328 46.1481L82.8855 137.404C78.4181 140.348 74.8595 143.797 74.937 145.108L83.332 287.012C83.4095 288.322 87.0939 286.998 91.5612 284.054L237.004 192.798C241.471 189.854 245.03 186.405 244.952 185.094L236.557 43.1904Z"
        fill="#DDF1FF"
      />
      <path
        d="M233.406 52.548C233.337 51.3887 230.272 52.4326 226.558 54.8796L105.656 130.493C101.943 132.94 98.9887 135.864 99.0573 137.023L106.484 262.553C106.552 263.712 109.618 262.668 113.331 260.221L234.233 184.608C237.946 182.161 240.901 179.237 240.832 178.078L233.406 52.548Z"
        fill="white"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="99"
        y="52"
        width="142"
        height="212"
      >
        <path
          d="M233.405 52.548C233.337 51.3887 230.271 52.4326 226.558 54.8796L105.656 130.493C101.943 132.94 98.9882 135.864 99.0568 137.023L106.483 262.553C106.552 263.712 109.617 262.668 113.331 260.221L234.233 184.608C237.946 182.161 240.9 179.237 240.832 178.078L233.405 52.548Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)">
        <g filter="url(#filter2_d)">
          <path
            className="notebook-line"
            d="M225.593 10.1855C225.569 9.76387 224.012 10.4335 222.116 11.6811L160.432 50.8032C158.537 52.0508 157.02 53.4039 157.045 53.8255L159.718 99.4731C159.743 99.8947 161.3 99.2251 163.195 97.9775L224.879 58.8554C226.775 57.6078 228.292 56.2547 228.267 55.8331L225.593 10.1855Z"
            fill="#7396FF"
          />
        </g>
        <circle
          r="11.5552"
          transform="matrix(0.835309 -0.549781 0.0584693 0.998289 176.22 65.375)"
          fill="#5578D7"
        />
        <path
          d="M179.88 59.0226C179.983 60.7923 178.839 64 177.358 64.9746C175.877 65.9493 174.621 64.3217 174.517 62.552C174.414 60.7823 175.53 58.5576 177.011 57.583C178.492 56.6083 179.776 57.2529 179.88 59.0226Z"
          fill="white"
        />
        <path
          d="M182.359 69.2882C182.258 67.56 181.586 66.2806 180.492 65.7316C179.398 65.1826 177.971 65.4088 176.525 66.3606C175.079 67.3124 173.732 68.9117 172.781 70.8067C171.831 72.7017 171.353 74.7372 171.454 76.4654L176.907 72.8768L182.359 69.2882Z"
          fill="white"
        />
        <rect
          width="15.7348"
          height="3.44198"
          transform="matrix(0.835309 -0.549781 0.0584693 0.998289 191.757 51.5762)"
          fill="#B3D9F5"
        />
        <rect
          width="19.6685"
          height="3.93369"
          transform="matrix(0.835309 -0.549781 0.0584693 0.998289 192.075 56.9766)"
          fill="#B3D9F5"
        />
        <g filter="url(#filter4_d)">
          <path
            d="M228.593 126.185C228.569 125.764 227.012 126.433 225.116 127.681L163.432 166.803C161.537 168.051 160.02 169.404 160.045 169.826L162.718 215.473C162.743 215.895 164.3 215.225 166.195 213.978L227.879 174.855C229.775 173.608 231.292 172.255 231.267 171.833L228.593 126.185Z"
            fill="#7396FF"
          />
        </g>
        <circle
          r="11.5552"
          transform="matrix(0.835309 -0.549781 0.0584693 0.998289 179.22 181.375)"
          fill="#5578D7"
        />
        <path
          d="M182.88 175.023C182.983 176.792 181.839 180 180.358 180.975C178.877 181.949 177.621 180.322 177.517 178.552C177.414 176.782 178.53 174.558 180.011 173.583C181.492 172.608 182.776 173.253 182.88 175.023Z"
          fill="white"
        />
        <path
          d="M185.359 185.288C185.258 183.56 184.586 182.281 183.492 181.732C182.398 181.183 180.971 181.409 179.525 182.361C178.079 183.312 176.732 184.912 175.781 186.807C174.831 188.702 174.353 190.737 174.454 192.465L179.907 188.877L185.359 185.288Z"
          fill="white"
        />
        <rect
          width="29.011"
          height="3.93369"
          transform="matrix(0.835309 -0.549781 0.0584693 0.998289 194.384 161.195)"
          fill="#B3D9F5"
        />
        <rect
          width="15.7348"
          height="3.44198"
          transform="matrix(0.835309 -0.549781 0.0584693 0.998289 194.757 167.576)"
          fill="#B3D9F5"
        />
        <rect
          width="19.6685"
          height="3.93369"
          transform="matrix(0.835309 -0.549781 0.0584693 0.998289 195.075 172.977)"
          fill="#B3D9F5"
        />
        <g filter="url(#filter5_d)">
          <path
            d="M228.593 186.277C228.569 185.856 227.012 186.525 225.116 187.773L163.432 226.895C161.537 228.143 160.02 229.496 160.045 229.917L162.718 275.565C162.743 275.987 164.3 275.317 166.195 274.069L227.879 234.947C229.775 233.7 231.292 232.346 231.267 231.925L228.593 186.277Z"
            fill="#7396FF"
          />
        </g>
        <circle
          r="11.5552"
          transform="matrix(0.835309 -0.549781 0.0584693 0.998289 179.22 241.467)"
          fill="#5578D7"
        />
        <path
          d="M182.88 235.114C182.983 236.884 181.839 240.092 180.358 241.066C178.877 242.041 177.621 240.413 177.517 238.644C177.414 236.874 178.53 234.649 180.011 233.675C181.492 232.7 182.776 233.345 182.88 235.114Z"
          fill="white"
        />
        <path
          d="M185.359 245.38C185.258 243.652 184.586 242.372 183.492 241.823C182.398 241.274 180.971 241.501 179.525 242.452C178.079 243.404 176.732 245.003 175.781 246.898C174.831 248.794 174.353 250.829 174.454 252.557L179.907 248.969L185.359 245.38Z"
          fill="white"
        />
        <rect
          width="29.011"
          height="3.93369"
          transform="matrix(0.835309 -0.549781 0.0584693 0.998289 194.384 221.287)"
          fill="#B3D9F5"
        />
        <rect
          width="15.7348"
          height="3.44198"
          transform="matrix(0.835309 -0.549781 0.0584693 0.998289 194.757 227.668)"
          fill="#B3D9F5"
        />
        <rect
          width="19.6685"
          height="3.93369"
          transform="matrix(0.835309 -0.549781 0.0584693 0.998289 195.075 233.067)"
          fill="#B3D9F5"
        />
      </g>
      <path
        opacity="0.08"
        d="M66.3604 170.299L27.1987 196.107L27.808 206.407L66.9697 180.599L66.3604 170.299Z"
        fill="black"
      />
      <g clipPath="url(#clip0)">
        <path
          d="M30.7922 209.09L32.3935 208.035L32.4489 208.971L30.8476 210.026L30.7922 209.09ZM32.8215 208.239C32.8292 208.368 32.9429 208.402 33.0756 208.315L33.1033 208.783C33.1109 208.912 33.0096 209.088 32.8769 209.176L30.475 210.758C30.3424 210.846 30.2286 210.812 30.221 210.683L30.1933 210.214C30.3259 210.127 30.4273 209.951 30.4196 209.822C30.412 209.693 30.2982 209.659 30.1656 209.746L30.1379 209.278C30.1302 209.149 30.2316 208.973 30.3642 208.886L32.7661 207.303C32.8988 207.215 33.0125 207.249 33.0202 207.379L33.0479 207.847C32.9152 207.934 32.8139 208.11 32.8215 208.239ZM32.5513 207.89C32.5475 207.826 32.4906 207.809 32.4243 207.852L30.743 208.96C30.6766 209.004 30.626 209.092 30.6298 209.157L30.6898 210.171C30.6936 210.236 30.7505 210.253 30.8168 210.209L32.4982 209.101C32.5645 209.057 32.6152 208.969 32.6113 208.905L32.5513 207.89Z"
          fill="#6A8AEB"
        />
      </g>
      <path
        d="M28.7212 195.295L27.7401 195.942L28.3494 206.242L29.3305 205.595L28.7212 195.295Z"
        fill="#DDE2FF"
      />
      <path
        d="M35.7243 196.826C35.5962 196.91 35.4832 196.946 35.3853 196.932C35.2887 196.917 35.2106 196.856 35.151 196.749C35.0928 196.642 35.058 196.492 35.0466 196.3C35.0351 196.106 35.0503 195.923 35.092 195.751C35.1336 195.578 35.1983 195.423 35.286 195.287C35.375 195.15 35.4841 195.038 35.6135 194.953C35.7415 194.869 35.8545 194.834 35.9524 194.847C36.0503 194.861 36.1284 194.922 36.1867 195.03C36.2448 195.136 36.2796 195.285 36.291 195.477C36.3024 195.671 36.2867 195.855 36.2438 196.029C36.2022 196.203 36.1369 196.358 36.0479 196.495C35.9602 196.631 35.8523 196.742 35.7243 196.826ZM35.7136 196.646C35.8583 196.551 35.9671 196.411 36.0401 196.228C36.1129 196.042 36.1421 195.828 36.1277 195.585C36.1133 195.341 36.062 195.181 35.9737 195.106C35.8853 195.029 35.7688 195.038 35.6241 195.133C35.4794 195.229 35.37 195.368 35.2957 195.553C35.2228 195.736 35.1935 195.949 35.2078 196.191C35.2221 196.433 35.2735 196.593 35.362 196.672C35.4517 196.75 35.5689 196.741 35.7136 196.646Z"
        fill="#DDE2FF"
      />
      <path
        d="M36.9233 196.014L36.4359 194.994L36.5992 194.886L37.0161 195.796L36.97 195.826L37.256 194.453L37.4115 194.351L37.0712 195.917L36.9233 196.014Z"
        fill="#DDE2FF"
      />
      <path
        d="M38.4542 194.845C38.4151 194.926 38.3632 195.004 38.2984 195.079C38.235 195.153 38.1687 195.212 38.0995 195.258C37.9997 195.324 37.9117 195.354 37.8355 195.349C37.7607 195.343 37.7005 195.304 37.6549 195.23C37.6105 195.154 37.5842 195.047 37.576 194.908C37.5681 194.774 37.5803 194.644 37.6127 194.518C37.6452 194.391 37.6936 194.277 37.7581 194.176C37.8239 194.074 37.9016 193.993 37.9913 193.934C38.0771 193.878 38.1522 193.855 38.2167 193.866C38.2811 193.875 38.3321 193.916 38.3697 193.989C38.4086 194.062 38.4319 194.164 38.4397 194.296L38.442 194.335L37.7122 194.816L37.7041 194.679L38.3456 194.257L38.3013 194.385C38.2949 194.232 38.2657 194.131 38.2135 194.082C38.1626 194.031 38.0924 194.036 38.0028 194.095C37.9093 194.157 37.8383 194.25 37.7898 194.374C37.7425 194.496 37.7235 194.635 37.7327 194.791C37.7426 194.959 37.7777 195.068 37.8378 195.118C37.8992 195.166 37.9837 195.155 38.0912 195.084C38.1476 195.047 38.2006 195 38.2504 194.942C38.3014 194.882 38.3491 194.81 38.3934 194.728L38.4542 194.845Z"
        fill="#DDE2FF"
      />
      <path
        d="M38.7408 194.817L38.6857 193.885C38.6821 193.825 38.6773 193.766 38.6712 193.708C38.6663 193.647 38.6588 193.589 38.6489 193.533L38.7967 193.435L38.8367 193.74L38.8152 193.747C38.8264 193.622 38.8598 193.511 38.9153 193.416C38.972 193.318 39.0375 193.245 39.1118 193.196C39.1284 193.185 39.1439 193.176 39.1582 193.17C39.1737 193.162 39.1879 193.156 39.2011 193.153L39.2085 193.346C39.1782 193.351 39.1439 193.367 39.1055 193.392C39.0389 193.436 38.9863 193.491 38.9476 193.558C38.9103 193.623 38.8838 193.693 38.8683 193.768C38.8539 193.84 38.8487 193.909 38.8527 193.976L38.8963 194.714L38.7408 194.817Z"
        fill="#DDE2FF"
      />
      <path
        d="M39.803 194.117L39.3156 193.096L39.4788 192.989L39.8958 193.898L39.8497 193.928L40.1357 192.556L40.2912 192.453L39.9509 194.019L39.803 194.117Z"
        fill="#DDE2FF"
      />
      <path
        d="M40.563 193.616L40.4865 192.322L40.642 192.219L40.7186 193.513L40.563 193.616ZM40.4387 191.784L40.6288 191.659L40.6428 191.895L40.4527 192.021L40.4387 191.784Z"
        fill="#DDE2FF"
      />
      <path
        d="M41.8268 192.623C41.7877 192.704 41.7358 192.781 41.671 192.856C41.6075 192.93 41.5412 192.99 41.4721 193.035C41.3722 193.101 41.2842 193.132 41.2081 193.126C41.1332 193.121 41.073 193.081 41.0275 193.008C40.9831 192.932 40.9568 192.824 40.9485 192.685C40.9406 192.552 40.9529 192.422 40.9853 192.295C41.0177 192.169 41.0662 192.055 41.1307 191.953C41.1965 191.851 41.2742 191.771 41.3638 191.712C41.4496 191.655 41.5248 191.632 41.5893 191.643C41.6537 191.652 41.7047 191.694 41.7422 191.767C41.7811 191.839 41.8045 191.941 41.8123 192.073L41.8146 192.112L41.0847 192.593L41.0767 192.457L41.7181 192.034L41.6738 192.162C41.6675 192.009 41.6382 191.908 41.5861 191.859C41.5352 191.809 41.465 191.813 41.3753 191.872C41.2819 191.934 41.2109 192.027 41.1624 192.152C41.1151 192.274 41.096 192.413 41.1053 192.569C41.1152 192.737 41.1502 192.846 41.2104 192.895C41.2718 192.944 41.3563 192.933 41.4638 192.862C41.5201 192.825 41.5732 192.777 41.623 192.719C41.674 192.659 41.7216 192.588 41.766 192.505L41.8268 192.623Z"
        fill="#DDE2FF"
      />
      <path
        d="M42.3614 192.431L41.9276 191.372L42.0908 191.264L42.4559 192.208L42.406 192.241L42.6459 190.899L42.7822 190.809L43.1531 191.748L43.1032 191.781L43.3392 190.442L43.4928 190.341L43.2121 191.87L43.0623 191.969L42.6746 191.013L42.7591 190.958L42.5112 192.332L42.3614 192.431Z"
        fill="#DDE2FF"
      />
      <g clipPath="url(#clip1)">
        <path
          d="M32.814 197.454L31.7762 198.138L32.5162 198.509C32.5445 198.523 32.5881 198.497 32.6135 198.449C32.7715 198.147 32.8727 197.813 32.8898 197.501C32.8927 197.448 32.8558 197.427 32.814 197.454V197.454ZM32.7247 197.161C32.6517 196.56 32.2047 196.336 31.6796 196.637C31.6396 196.66 31.6083 196.723 31.6112 196.771L31.6804 197.941L32.6591 197.296C32.6991 197.269 32.7303 197.207 32.7247 197.161ZM31.4853 198.33L31.4119 197.089C31.4089 197.039 31.3698 197.022 31.3289 197.057C30.8129 197.488 30.4467 198.295 30.5039 198.935C30.5626 199.593 31.0776 199.824 31.6378 199.446C31.8581 199.298 32.0567 199.079 32.2158 198.826C32.2486 198.774 32.2473 198.712 32.2152 198.696L31.4853 198.33Z"
          fill="#DDE2FF"
        />
      </g>
      <path
        d="M37.2669 206.631L37.1711 205.013L36.5418 205.427L36.5314 205.251L38.0006 204.283L38.011 204.459L37.3817 204.874L37.4775 206.492L37.2669 206.631Z"
        fill="#6A8AEB"
      />
      <path
        d="M38.3657 205.907L38.2907 204.639L38.4962 204.503L38.5712 205.771L38.3657 205.907ZM38.2386 204.115L38.4898 203.95L38.5035 204.182L38.2523 204.347L38.2386 204.115Z"
        fill="#6A8AEB"
      />
      <path
        d="M39.5384 205.152C39.4133 205.235 39.3034 205.279 39.2089 205.286C39.1143 205.292 39.0388 205.263 38.9823 205.199C38.9275 205.134 38.8962 205.035 38.8883 204.902C38.8805 204.771 38.8991 204.639 38.944 204.507C38.9888 204.373 39.056 204.247 39.1458 204.13C39.2356 204.014 39.3439 203.913 39.4708 203.83C39.557 203.773 39.6408 203.733 39.7221 203.711C39.805 203.686 39.8742 203.683 39.9297 203.7L39.8702 203.897C39.8047 203.889 39.7408 203.894 39.6785 203.91C39.6178 203.924 39.5553 203.952 39.491 203.994C39.3625 204.079 39.2627 204.189 39.1916 204.325C39.1223 204.46 39.0922 204.606 39.1014 204.762C39.1106 204.916 39.1549 205.011 39.2343 205.046C39.3137 205.081 39.4185 205.056 39.5487 204.97C39.6113 204.929 39.6724 204.876 39.7319 204.813C39.7913 204.747 39.8509 204.67 39.9106 204.58L39.988 204.686C39.9369 204.772 39.8707 204.857 39.7896 204.942C39.7101 205.024 39.6264 205.094 39.5384 205.152Z"
        fill="#6A8AEB"
      />
      <path
        d="M41.1502 204.072L40.4166 203.882L41.0221 202.841L41.2834 202.669L40.6129 203.813L40.6129 203.679L41.4192 203.894L41.1502 204.072ZM40.29 204.639L40.1803 202.783L40.3858 202.648L40.4956 204.503L40.29 204.639Z"
        fill="#6A8AEB"
      />
      <path
        d="M42.6418 202.932C42.5891 203.02 42.5196 203.109 42.4334 203.197C42.3489 203.284 42.261 203.358 42.1697 203.418C42.0377 203.505 41.922 203.554 41.8225 203.566C41.7247 203.576 41.6467 203.55 41.5885 203.487C41.5319 203.421 41.4995 203.32 41.4915 203.184C41.4837 203.053 41.5023 202.921 41.5472 202.789C41.5921 202.656 41.6577 202.533 41.7441 202.418C41.8321 202.302 41.9354 202.206 42.0538 202.127C42.1671 202.053 42.2659 202.014 42.3501 202.011C42.4341 202.006 42.5001 202.036 42.5478 202.1C42.5973 202.164 42.6258 202.26 42.6335 202.39L42.6357 202.427L41.6715 203.063L41.6636 202.929L42.5111 202.371L42.4506 202.508C42.4453 202.357 42.409 202.264 42.3417 202.226C42.2761 202.187 42.1841 202.207 42.0657 202.285C41.9422 202.366 41.8475 202.474 41.7817 202.608C41.7174 202.739 41.6898 202.882 41.6988 203.034C41.7085 203.199 41.7523 203.3 41.83 203.336C41.9094 203.371 42.0201 203.341 42.1622 203.248C42.2367 203.199 42.307 203.14 42.3733 203.072C42.4411 203.001 42.5049 202.92 42.5645 202.828L42.6418 202.932Z"
        fill="#6A8AEB"
      />
      <path
        d="M42.7642 201.858L42.7547 201.697L43.5743 201.157L43.5838 201.318L42.7642 201.858ZM43.6335 202.247L43.6437 202.421C43.6155 202.448 43.5871 202.473 43.5585 202.495C43.5317 202.518 43.5014 202.54 43.4676 202.563C43.3492 202.641 43.2525 202.67 43.1775 202.649C43.1041 202.628 43.0635 202.55 43.0555 202.415L42.9841 201.208L43.1853 200.999L43.2594 202.252C43.2634 202.319 43.2758 202.365 43.2966 202.39C43.3191 202.414 43.3477 202.422 43.3824 202.415C43.417 202.406 43.4555 202.387 43.4978 202.36C43.5231 202.343 43.5459 202.326 43.566 202.309C43.5861 202.293 43.6086 202.272 43.6335 202.247Z"
        fill="#6A8AEB"
      />
      <path
        d="M44.3611 201.974C44.1462 202.116 43.973 202.173 43.8413 202.146L43.9009 201.95C43.9717 201.957 44.0433 201.95 44.1158 201.929C44.19 201.904 44.271 201.863 44.359 201.805C44.4554 201.742 44.5272 201.678 44.5744 201.613C44.6231 201.546 44.6457 201.482 44.642 201.42C44.639 201.369 44.6198 201.341 44.5842 201.335C44.5486 201.327 44.4913 201.339 44.4124 201.372L44.1938 201.464C44.0858 201.51 44.0004 201.523 43.9377 201.501C43.8766 201.479 43.8435 201.426 43.8386 201.341C43.8342 201.268 43.8507 201.189 43.888 201.107C43.9252 201.023 43.9792 200.942 44.0501 200.862C44.1209 200.782 44.2063 200.709 44.3061 200.643C44.3957 200.584 44.4811 200.543 44.5623 200.519C44.6435 200.495 44.7128 200.492 44.7701 200.51L44.7104 200.704C44.6465 200.694 44.5817 200.698 44.5161 200.717C44.452 200.733 44.3853 200.764 44.3159 200.81C44.2246 200.87 44.1546 200.934 44.1059 201.003C44.0589 201.071 44.0372 201.135 44.0408 201.195C44.0439 201.247 44.0615 201.278 44.0938 201.289C44.1277 201.298 44.1799 201.288 44.2502 201.259L44.469 201.17C44.5906 201.116 44.6811 201.1 44.7404 201.122C44.8013 201.141 44.8343 201.195 44.8396 201.284C44.8461 201.393 44.8064 201.51 44.7206 201.635C44.6349 201.759 44.515 201.873 44.3611 201.974Z"
        fill="#6A8AEB"
      />
      <path
        opacity="0.8"
        d="M291.716 265.622L298.84 260.897C299.337 260.518 299.337 259.951 298.674 259.384L290.39 253.903C289.728 253.525 288.734 253.336 288.237 253.714L281.113 258.439C280.616 258.817 280.616 259.384 281.278 259.951L289.562 265.433C290.059 265.811 291.053 266 291.716 265.622Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M280.781 272.614L287.905 267.889C288.402 267.511 288.402 266.944 287.739 266.377L279.456 260.895C278.793 260.517 277.799 260.328 277.302 260.706L270.178 265.431C269.681 265.81 269.681 266.377 270.344 266.944L278.627 272.425C279.29 272.992 280.118 272.992 280.781 272.614Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M269.848 279.798L276.972 275.072C277.469 274.694 277.469 274.127 276.806 273.56L268.523 268.079C267.86 267.701 266.866 267.512 266.369 267.89L259.245 272.615C258.748 272.993 258.748 273.56 259.411 274.127L267.694 279.609C268.357 280.176 269.351 280.176 269.848 279.798Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M254.77 273.937L261.894 269.211C262.391 268.833 262.391 268.266 261.728 267.699L254.77 263.163C254.107 262.785 253.113 262.596 252.616 262.974L245.492 267.699C244.995 268.077 244.995 268.644 245.658 269.211L252.616 273.748C253.279 274.315 254.273 274.315 254.77 273.937Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M244.001 281.12L251.125 276.395C251.622 276.017 251.622 275.45 250.96 274.883L244.001 270.346C243.339 269.968 242.345 269.779 241.848 270.157L234.724 274.883C234.227 275.261 234.227 275.828 234.889 276.395L241.848 280.931C242.345 281.498 243.339 281.498 244.001 281.12Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M233.069 288.303L240.193 283.577C240.69 283.199 240.69 282.632 240.027 282.065L233.069 277.529C232.406 277.151 231.412 276.962 230.915 277.34L223.791 282.065C223.294 282.443 223.294 283.01 223.957 283.577L230.915 288.114C231.578 288.681 232.572 288.681 233.069 288.303Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M222.134 295.485L229.258 290.76C229.755 290.382 229.755 289.815 229.092 289.248L222.134 284.711C221.471 284.333 220.477 284.144 219.98 284.522L212.856 289.248C212.359 289.626 212.359 290.193 213.022 290.76L219.98 295.296C220.643 295.863 221.637 295.863 222.134 295.485Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M211.366 302.668L218.489 297.943C218.986 297.565 218.987 296.998 218.324 296.43L211.366 291.894C210.703 291.516 209.709 291.327 209.212 291.705L202.088 296.43C201.591 296.809 201.591 297.376 202.254 297.943L209.212 302.479C209.709 302.857 210.703 303.046 211.366 302.668Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M200.431 309.85L207.555 305.124C208.052 304.746 208.052 304.179 207.389 303.612L200.431 299.076C199.768 298.698 198.774 298.509 198.277 298.887L191.153 303.612C190.656 303.99 190.656 304.557 191.319 305.124L198.277 309.661C198.94 310.039 199.934 310.228 200.431 309.85Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M189.496 317.032L196.62 312.307C197.117 311.929 197.117 311.362 196.454 310.795L189.496 306.258C188.833 305.88 187.839 305.691 187.342 306.069L180.218 310.795C179.721 311.173 179.721 311.74 180.384 312.307L187.342 316.843C188.005 317.221 188.999 317.41 189.496 317.032Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M178.728 324.026L185.852 319.301C186.349 318.923 186.349 318.356 185.686 317.789L178.728 313.252C178.065 312.874 177.071 312.685 176.574 313.063L169.45 317.789C168.953 318.167 168.953 318.734 169.616 319.301L176.574 323.837C177.071 324.405 178.065 324.405 178.728 324.026Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M167.793 331.21L174.917 326.485C175.414 326.107 175.414 325.54 174.751 324.972L167.793 320.436C167.13 320.058 166.136 319.869 165.639 320.247L158.515 324.972C158.018 325.35 158.018 325.918 158.681 326.485L165.639 331.021C166.302 331.588 167.296 331.588 167.793 331.21Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M156.858 338.393L163.982 333.667C164.479 333.289 164.479 332.722 163.816 332.155L156.858 327.619C156.196 327.241 155.202 327.052 154.705 327.43L147.581 332.155C147.084 332.533 147.084 333.1 147.746 333.667L154.705 338.204C155.367 338.771 156.361 338.771 156.858 338.393Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M238.867 270.536L245.991 265.811C246.488 265.433 246.488 264.866 245.825 264.299L238.867 259.762C238.204 259.384 237.21 259.195 236.713 259.573L229.589 264.299C229.092 264.677 229.092 265.244 229.755 265.811L236.713 270.347C237.21 270.914 238.204 270.914 238.867 270.536Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M249.802 263.352L256.926 258.626C257.423 258.248 257.423 257.681 256.76 257.114L249.802 252.578C249.139 252.2 248.145 252.011 247.648 252.389L240.524 257.114C240.027 257.492 240.027 258.059 240.69 258.626L247.648 263.163C248.311 263.541 249.305 263.73 249.802 263.352Z"
        fill="#6FAEEC"
      />
      <path
        className="notebook-button"
        opacity="0.8"
        d="M260.9 255.981L276.142 245.963C276.639 245.585 276.639 245.018 275.976 244.451L269.018 239.915C268.355 239.537 267.361 239.348 266.864 239.726L251.622 249.743C251.125 250.121 251.125 250.689 251.788 251.256L258.746 255.792C259.409 256.359 260.403 256.359 260.9 255.981Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M253.114 247.097L265.373 238.969C265.87 238.591 265.87 238.024 265.208 237.457L258.249 232.92C257.587 232.542 256.593 232.353 256.096 232.731L243.836 240.859C243.339 241.237 243.339 241.804 244.002 242.371L250.96 246.908C251.623 247.475 252.617 247.475 253.114 247.097Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M227.932 277.717L235.056 272.991C235.553 272.613 235.553 272.046 234.89 271.479L227.932 266.943C227.269 266.565 226.275 266.376 225.778 266.754L218.654 271.479C218.157 271.857 218.157 272.424 218.82 272.991L225.778 277.528C226.441 278.095 227.435 278.095 227.932 277.717Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M216.997 284.9L224.121 280.175C224.618 279.797 224.618 279.23 223.956 278.663L216.997 274.127C216.335 273.748 215.341 273.559 214.844 273.938L207.72 278.663C207.223 279.041 207.223 279.608 207.885 280.175L214.844 284.711C215.506 285.278 216.5 285.278 216.997 284.9Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M206.229 292.084L213.353 287.359C213.85 286.981 213.85 286.414 213.187 285.847L206.229 281.31C205.566 280.932 204.572 280.743 204.075 281.121L196.951 285.847C196.454 286.225 196.454 286.792 197.117 287.359L204.075 291.895C204.572 292.462 205.566 292.462 206.229 292.084Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M195.294 299.265L202.418 294.539C202.915 294.161 202.915 293.594 202.253 293.027L195.294 288.491C194.632 288.113 193.638 287.924 193.141 288.302L186.017 293.027C185.52 293.405 185.52 293.972 186.182 294.539L193.141 299.076C193.803 299.454 194.797 299.643 195.294 299.265Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M184.36 306.449L191.484 301.724C191.981 301.346 191.981 300.779 191.318 300.212L184.36 295.675C183.697 295.297 182.703 295.108 182.206 295.486L175.082 300.212C174.585 300.59 174.585 301.157 175.248 301.724L182.206 306.26C182.869 306.638 183.863 306.827 184.36 306.449Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M173.591 313.63L180.715 308.905C181.212 308.527 181.212 307.959 180.549 307.392L173.591 302.856C172.929 302.478 171.934 302.289 171.437 302.667L164.314 307.392C163.817 307.77 163.817 308.337 164.479 308.905L171.437 313.441C171.934 313.819 172.929 314.008 173.591 313.63Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M162.659 320.813L169.782 316.088C170.279 315.71 170.279 315.143 169.617 314.576L162.659 310.04C161.996 309.662 161.002 309.473 160.505 309.851L153.381 314.576C152.884 314.954 152.884 315.521 153.547 316.088L160.505 320.624C161.167 321.002 162.162 321.002 162.659 320.813Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M151.724 327.806L158.847 323.08C159.344 322.702 159.344 322.135 158.682 321.568L151.724 317.032C151.061 316.654 150.067 316.465 149.57 316.843L142.446 321.568C141.949 321.946 141.949 322.513 142.612 323.08L149.57 327.617C150.233 328.184 151.227 328.184 151.724 327.806Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M140.955 334.989L148.079 330.264C148.576 329.886 148.576 329.319 147.914 328.752L140.955 324.215C140.293 323.837 139.299 323.648 138.802 324.026L131.678 328.752C131.181 329.13 131.181 329.697 131.843 330.264L138.802 334.8C139.299 335.367 140.293 335.367 140.955 334.989Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M121.571 347.844L136.978 337.637C137.475 337.259 137.475 336.692 136.813 336.125L129.854 331.588C129.192 331.21 128.198 331.021 127.701 331.399L112.293 341.606C111.796 341.984 111.796 342.551 112.459 343.118L119.417 347.655C120.08 348.033 121.074 348.222 121.571 347.844Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M100.365 333.856L113.287 325.35C113.784 324.972 113.784 324.405 113.122 323.838L106.163 319.301C105.501 318.923 104.507 318.734 104.01 319.112L91.0873 327.618C90.5903 327.996 90.5903 328.563 91.253 329.13L98.2112 333.667C98.8739 334.234 99.8679 334.234 100.365 333.856Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M208.88 276.395L216.004 271.669C216.501 271.291 216.501 270.724 215.838 270.157L208.88 265.621C208.218 265.243 207.224 265.054 206.727 265.432L199.603 270.157C199.106 270.535 199.106 271.102 199.768 271.669L206.727 276.206C207.389 276.584 208.383 276.773 208.88 276.395Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M219.98 269.023L227.104 264.297C227.601 263.919 227.601 263.352 226.938 262.785L219.98 258.249C219.317 257.871 218.323 257.682 217.826 258.06L210.702 262.785C210.205 263.163 210.205 263.73 210.868 264.297L217.826 268.833C218.489 269.212 219.483 269.401 219.98 269.023Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M231.245 261.65L238.369 256.925C238.866 256.547 238.866 255.98 238.204 255.413L231.245 250.877C230.583 250.498 229.589 250.309 229.092 250.688L221.968 255.413C221.471 255.791 221.471 256.358 222.134 256.925L229.092 261.461C229.589 262.028 230.583 262.028 231.245 261.65Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M242.345 254.28L249.469 249.555C249.966 249.177 249.966 248.61 249.303 248.043L242.345 243.506C241.682 243.128 240.688 242.939 240.191 243.317L233.067 248.043C232.57 248.421 232.57 248.988 233.233 249.555L240.191 254.091C240.854 254.658 241.682 254.658 242.345 254.28Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M197.946 283.579L205.07 278.854C205.567 278.476 205.567 277.909 204.904 277.342L197.946 272.805C197.283 272.427 196.289 272.238 195.792 272.616L188.668 277.342C188.171 277.72 188.171 278.287 188.834 278.854L195.792 283.39C196.455 283.768 197.449 283.768 197.946 283.579Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M187.177 290.571L194.301 285.846C194.798 285.468 194.798 284.901 194.135 284.334L187.177 279.797C186.514 279.419 185.52 279.23 185.023 279.608L177.899 284.334C177.402 284.712 177.402 285.279 178.065 285.846L185.023 290.382C185.52 290.949 186.514 290.949 187.177 290.571Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M176.242 297.755L183.366 293.03C183.863 292.652 183.863 292.084 183.201 291.517L176.242 286.981C175.58 286.603 174.586 286.414 174.089 286.792L166.965 291.517C166.468 291.895 166.468 292.462 167.131 293.03L174.089 297.566C174.751 298.133 175.745 298.133 176.242 297.755Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M165.308 304.936L172.432 300.21C172.929 299.832 172.929 299.265 172.266 298.698L165.308 294.162C164.645 293.784 163.651 293.595 163.154 293.973L156.03 298.698C155.533 299.076 155.533 299.643 156.196 300.21L163.154 304.747C163.817 305.314 164.811 305.314 165.308 304.936Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M154.539 312.119L161.663 307.394C162.16 307.016 162.16 306.449 161.498 305.882L154.539 301.345C153.877 300.967 152.883 300.778 152.386 301.156L145.262 305.882C144.765 306.26 144.765 306.827 145.427 307.394L152.386 311.93C152.883 312.497 153.877 312.497 154.539 312.119Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M143.605 319.301L150.729 314.575C151.226 314.197 151.226 313.63 150.563 313.063L143.605 308.527C142.942 308.149 141.948 307.96 141.451 308.338L134.327 313.063C133.83 313.441 133.83 314.008 134.493 314.575L141.451 319.112C142.114 319.679 143.108 319.679 143.605 319.301Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M132.672 326.484L139.796 321.759C140.293 321.381 140.293 320.814 139.63 320.247L132.672 315.711C132.009 315.332 131.015 315.143 130.518 315.521L123.394 320.247C122.897 320.625 122.897 321.192 123.56 321.759L130.518 326.295C131.181 326.862 132.175 326.862 132.672 326.484Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M121.902 333.668L129.026 328.943C129.523 328.565 129.523 327.998 128.86 327.43L121.902 322.894C121.239 322.516 120.245 322.327 119.748 322.705L112.624 327.43C112.127 327.809 112.127 328.376 112.79 328.943L119.748 333.479C120.245 334.046 121.239 334.046 121.902 333.668Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M110.969 340.849L118.093 336.123C118.59 335.745 118.59 335.178 117.927 334.611L110.969 330.075C110.306 329.697 109.312 329.508 108.815 329.886L101.691 334.611C101.194 334.989 101.194 335.556 101.857 336.123L108.815 340.66C109.478 341.227 110.472 341.227 110.969 340.849Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M214.679 258.626L221.802 253.901C222.299 253.523 222.299 252.956 221.637 252.388L214.679 247.852C214.016 247.474 213.022 247.285 212.525 247.663L205.401 252.388C204.904 252.767 204.904 253.334 205.567 253.901L212.525 258.437C213.022 259.004 214.016 259.004 214.679 258.626Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M225.447 251.445L232.571 246.72C233.068 246.342 233.068 245.775 232.405 245.208L225.447 240.671C224.784 240.293 223.79 240.104 223.293 240.482L216.17 245.208C215.673 245.586 215.673 246.153 216.335 246.72L223.293 251.256C223.956 251.823 224.95 251.823 225.447 251.445Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M236.216 244.262L243.34 239.536C243.837 239.158 243.837 238.591 243.174 238.024L236.216 233.488C235.553 233.11 234.559 232.921 234.062 233.299L226.938 238.024C226.441 238.402 226.441 238.969 227.104 239.536L234.062 244.073C234.725 244.64 235.719 244.64 236.216 244.262Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M247.15 237.27L254.274 232.544C254.771 232.166 254.771 231.599 254.108 231.032L247.15 226.496C246.488 226.118 245.494 225.929 244.997 226.307L237.873 231.032C237.376 231.41 237.376 231.977 238.038 232.544L244.997 237.081C245.494 237.459 246.488 237.648 247.15 237.27Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M235.885 229.897L243.009 225.171C243.506 224.793 243.506 224.226 242.843 223.659L240.523 222.147C239.861 221.769 238.867 221.58 238.37 221.958L231.246 226.683C230.749 227.061 230.749 227.628 231.412 228.196L233.731 229.708C234.394 230.275 235.388 230.275 235.885 229.897Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M221.803 239.158L228.926 234.433C229.424 234.055 229.423 233.488 228.761 232.921L226.441 231.409C225.779 231.031 224.785 230.842 224.288 231.22L217.164 235.945C216.667 236.323 216.667 236.89 217.33 237.457L219.649 238.969C220.312 239.536 221.306 239.536 221.803 239.158Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M211.035 246.341L218.158 241.616C218.655 241.238 218.655 240.671 217.993 240.103L215.673 238.591C215.011 238.213 214.017 238.024 213.52 238.402L206.396 243.128C205.899 243.506 205.899 244.073 206.561 244.64L208.881 246.152C209.544 246.53 210.538 246.719 211.035 246.341Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M200.265 253.335L207.388 248.61C207.885 248.232 207.885 247.665 207.223 247.098L204.903 245.586C204.241 245.207 203.247 245.018 202.75 245.396L195.626 250.122C195.129 250.5 195.129 251.067 195.791 251.634L198.111 253.146C198.773 253.713 199.767 253.713 200.265 253.335Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M189.496 260.517L196.62 255.791C197.117 255.413 197.117 254.846 196.454 254.279L194.135 252.767C193.472 252.389 192.478 252.2 191.981 252.578L184.857 257.304C184.36 257.682 184.36 258.249 185.023 258.816L187.342 260.328C188.005 260.895 188.999 260.895 189.496 260.517Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M129.357 300.21L136.481 295.485C136.978 295.107 136.978 294.54 136.315 293.973L133.996 292.46C133.333 292.082 132.339 291.893 131.842 292.271L124.718 296.997C124.221 297.375 124.221 297.942 124.884 298.509L127.203 300.021C127.7 300.399 128.694 300.588 129.357 300.21Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M118.589 307.204L125.713 302.479C126.21 302.101 126.21 301.534 125.547 300.967L123.228 299.455C122.565 299.077 121.571 298.888 121.074 299.266L113.95 303.991C113.453 304.369 113.453 304.936 114.116 305.503L116.435 307.015C116.932 307.582 117.926 307.582 118.589 307.204Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M107.82 314.386L114.944 309.661C115.441 309.283 115.441 308.715 114.778 308.148L112.459 306.636C111.796 306.258 110.802 306.069 110.305 306.447L103.181 311.173C102.684 311.551 102.684 312.118 103.347 312.685L105.666 314.197C106.164 314.575 107.158 314.764 107.82 314.386Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M96.8856 321.38L104.009 316.655C104.507 316.277 104.506 315.71 103.844 315.143L101.524 313.63C100.862 313.252 99.8677 313.063 99.3707 313.441L92.2468 318.167C91.7498 318.545 91.7498 319.112 92.4125 319.679L94.7319 321.191C95.3946 321.758 96.3886 321.758 96.8856 321.38Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M203.744 265.81L210.868 261.084C211.365 260.706 211.365 260.139 210.702 259.572L203.744 255.036C203.081 254.658 202.087 254.469 201.59 254.847L194.466 259.572C193.969 259.95 193.969 260.517 194.632 261.084L201.59 265.621C202.253 265.999 203.081 266.188 203.744 265.81Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M192.809 272.991L199.933 268.266C200.43 267.888 200.43 267.321 199.768 266.754L192.809 262.217C192.147 261.839 191.153 261.65 190.656 262.028L183.532 266.754C183.035 267.132 183.035 267.699 183.697 268.266L190.656 272.802C191.318 273.18 192.312 273.369 192.809 272.991Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M182.041 280.175L189.165 275.449C189.662 275.071 189.662 274.504 188.999 273.937L182.041 269.401C181.378 269.023 180.384 268.834 179.887 269.212L172.763 273.937C172.266 274.315 172.266 274.882 172.929 275.449L179.887 279.986C180.384 280.364 181.378 280.553 182.041 280.175Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M171.106 287.167L178.23 282.442C178.727 282.064 178.727 281.497 178.065 280.93L171.106 276.393C170.444 276.015 169.45 275.826 168.953 276.204L161.829 280.93C161.332 281.308 161.332 281.875 161.994 282.442L168.953 286.978C169.615 287.545 170.609 287.545 171.106 287.167Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M160.173 294.352L167.297 289.626C167.794 289.248 167.794 288.681 167.131 288.114L160.173 283.578C159.511 283.2 158.517 283.011 158.019 283.389L150.896 288.114C150.399 288.492 150.399 289.059 151.061 289.626L158.019 294.163C158.682 294.73 159.676 294.73 160.173 294.352Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M149.403 301.535L156.527 296.81C157.024 296.432 157.024 295.865 156.361 295.298L149.403 290.761C148.741 290.383 147.746 290.194 147.249 290.572L140.126 295.298C139.629 295.676 139.629 296.243 140.291 296.81L147.249 301.346C147.746 301.913 148.741 301.913 149.403 301.535Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M138.47 308.716L145.594 303.99C146.091 303.612 146.091 303.045 145.428 302.478L138.47 297.942C137.807 297.564 136.813 297.375 136.316 297.753L129.193 302.478C128.696 302.856 128.696 303.423 129.358 303.99L136.316 308.527C136.979 309.094 137.973 309.094 138.47 308.716Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M127.536 315.899L134.659 311.174C135.156 310.796 135.156 310.229 134.494 309.662L127.536 305.126C126.873 304.748 125.879 304.558 125.382 304.937L118.258 309.662C117.761 310.04 117.761 310.607 118.424 311.174L125.382 315.71C126.045 316.277 127.039 316.277 127.536 315.899Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M116.765 323.081L123.889 318.356C124.386 317.978 124.386 317.411 123.724 316.844L116.765 312.307C116.103 311.929 115.109 311.74 114.612 312.118L107.488 316.844C106.991 317.222 106.991 317.789 107.653 318.356L114.612 322.892C115.109 323.459 116.103 323.459 116.765 323.081Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M265.87 266.565L286.579 252.956C287.076 252.578 287.076 252.011 286.414 251.444L279.455 246.908C278.793 246.53 277.799 246.341 277.302 246.719L256.593 260.328C256.096 260.706 256.096 261.273 256.758 261.84L263.717 266.376C264.379 266.943 265.373 266.943 265.87 266.565Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M132.34 354.647L153.049 341.037C153.546 340.659 153.546 340.092 152.883 339.525L145.925 334.989C145.262 334.611 144.268 334.422 143.771 334.8L123.062 348.409C122.565 348.787 122.565 349.354 123.228 349.921L130.186 354.458C130.683 355.025 131.677 355.025 132.34 354.647Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M175.745 341.794L182.869 337.068C183.366 336.69 183.366 336.123 182.704 335.556L174.42 330.075C173.757 329.697 172.763 329.508 172.266 329.886L165.142 334.611C164.645 334.989 164.645 335.556 165.308 336.123L173.592 341.605C174.254 342.172 175.248 342.172 175.745 341.794Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M254.77 289.814L266.035 282.442C266.532 282.064 266.532 281.497 265.87 280.93L257.586 275.449C256.923 275.071 255.929 274.882 255.432 275.26L244.167 282.631C243.67 283.009 243.67 283.576 244.332 284.144L252.616 289.625C253.279 290.192 254.273 290.192 254.77 289.814Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M186.183 334.989L197.448 327.617C197.945 327.239 197.945 326.672 197.283 326.105L188.999 320.624C188.337 320.246 187.342 320.057 186.845 320.435L175.58 327.806C175.083 328.184 175.083 328.751 175.745 329.318L184.029 334.8C184.692 335.367 185.686 335.367 186.183 334.989Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M239.031 300.21L250.297 292.838C250.794 292.46 250.794 291.893 250.131 291.326L241.848 285.844C241.185 285.466 240.191 285.277 239.694 285.655L228.428 293.027C227.931 293.405 227.931 293.972 228.594 294.539L236.878 300.021C237.54 300.399 238.534 300.588 239.031 300.21Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M239.53 285.656L190.491 317.978C189.828 318.356 189.828 319.112 190.491 319.49L198.94 325.161C199.603 325.539 200.597 325.539 201.094 325.161L250.298 292.839L239.53 285.656Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M145.428 354.835L137.642 359.939C137.145 360.317 137.31 360.695 137.807 361.073L141.286 363.341C141.783 363.719 142.612 363.719 143.109 363.341L150.895 358.238C151.392 357.86 151.227 357.482 150.73 357.104L147.25 354.835C146.588 354.646 145.759 354.457 145.428 354.835Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M156.362 347.654L148.575 352.757C148.078 353.135 148.244 353.513 148.741 353.891L152.22 356.159C152.717 356.537 153.545 356.537 154.042 356.159L161.829 351.056C162.326 350.678 162.16 350.3 161.663 349.922L158.184 347.654C157.687 347.276 156.859 347.276 156.362 347.654Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M167.296 340.47L159.51 345.574C159.013 345.952 159.178 346.33 159.675 346.708L163.154 348.976C163.651 349.354 164.48 349.354 164.977 348.976L172.763 343.873C173.26 343.495 173.095 343.116 172.598 342.738L169.119 340.47C168.456 340.281 167.628 340.092 167.296 340.47Z"
        fill="#6FAEEC"
      />
      <path
        opacity="0.8"
        d="M150.398 343.686L142.612 348.789C142.115 349.167 142.28 349.545 142.777 349.923L146.256 352.192C146.753 352.57 147.582 352.57 148.079 352.192L155.865 347.088C156.362 346.71 156.197 346.332 155.7 345.954L152.221 343.686C151.558 343.308 150.73 343.308 150.398 343.686Z"
        fill="#6FAEEC"
      />
      <g filter="url(#filter6_d)">
        <path
          d="M138.909 143.473C138.904 143.296 137.571 144.382 137.571 144.382L107.791 162.246L105.837 163.397L109.233 253.649C109.238 253.826 109.985 253.509 110.901 252.941L141.299 234.522C142.215 233.953 142.953 233.349 142.947 233.172L138.909 143.473Z"
          fill="#A5C8FF"
        />
      </g>
      <g filter="url(#filter7_d)">
        <path
          d="M138.305 124.278C138.299 124.101 137.552 124.418 136.637 124.986L106.857 142.85C105.941 143.418 105.204 144.022 105.209 144.2L105.818 163.416C105.824 163.594 106.57 163.277 107.486 162.709L137.266 144.845C138.182 144.277 138.919 143.672 138.914 143.495L138.305 124.278Z"
          fill="#7396FF"
        />
      </g>
      <g className="notebook-card">
        <g filter="url(#filter3_d)">
          <path
            className="notebook-rect"
            d="M225.593 70.2772C225.569 69.8557 224.012 70.5253 222.116 71.7729L160.432 110.895C158.537 112.143 157.02 113.496 157.045 113.917L159.718 159.565C159.743 159.987 161.3 159.317 163.195 158.069L224.879 118.947C226.775 117.7 228.292 116.346 228.267 115.925L225.593 70.2772Z"
            fill="#7396FF"
          />
        </g>
        <circle
          className="notebook-circle"
          r="11.5552"
          transform="matrix(0.835309 -0.549781 0.0584693 0.998289 176.22 125.467)"
          fill="#5578D7"
        />
        <g className="notebook-person">
          <path
            d="M179.88 119.114C179.983 120.884 178.839 124.092 177.358 125.066C175.877 126.041 174.621 124.413 174.517 122.644C174.414 120.874 175.53 118.649 177.011 117.675C178.492 116.7 179.776 117.345 179.88 119.114Z"
            fill="white"
          />
          <path
            d="M182.359 129.38C182.258 127.652 181.586 126.372 180.492 125.823C179.398 125.274 177.971 125.501 176.525 126.452C175.079 127.404 173.732 129.003 172.781 130.898C171.831 132.794 171.353 134.829 171.454 136.557L176.907 132.969L182.359 129.38Z"
            fill="white"
          />
        </g>
        <g className="notebook-tiles">
          <rect
            width="29.011"
            height="3.93369"
            transform="matrix(0.835309 -0.549781 0.0584693 0.998289 191.384 105.287)"
            fill="#B3D9F5"
          />
          <rect
            width="15.7348"
            height="3.44198"
            transform="matrix(0.835309 -0.549781 0.0584693 0.998289 191.757 111.668)"
            fill="#B3D9F5"
          />
          <rect
            width="19.6685"
            height="3.93369"
            transform="matrix(0.835309 -0.549781 0.0584693 0.998289 192.075 117.067)"
            fill="#B3D9F5"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_di"
          x="0.637695"
          y="0.65332"
          width="273.677"
          height="350.549"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="40" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow" />
        </filter>
        <filter
          id="filter1_d"
          x="21.0298"
          y="138.252"
          width="77.4533"
          height="198.564"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="10" dy="4" />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d"
          x="152.044"
          y="-0.94043"
          width="101.223"
          height="119.54"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="10" dy="4" />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d"
          x="152.044"
          y="59.1514"
          width="101.223"
          height="119.54"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="10" dy="4" />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d"
          x="155.044"
          y="115.06"
          width="101.223"
          height="119.54"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="10" dy="4" />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <filter
          id="filter5_d"
          x="155.044"
          y="175.151"
          width="101.223"
          height="119.54"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="10" dy="4" />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <filter
          id="filter6_d"
          x="100.837"
          y="132.453"
          width="67.11"
          height="140.245"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="10" dy="4" />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <filter
          id="filter7_d"
          x="100.209"
          y="113.229"
          width="63.7047"
          height="69.2365"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="10" dy="4" />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear"
          x1="29.8412"
          y1="320.094"
          x2="395.613"
          y2="320.094"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F2F2F2" />
          <stop offset="0.3876" stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#F2F2F2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="-132.658"
          y1="308.684"
          x2="406.32"
          y2="308.684"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DEDDFF" />
          <stop offset="0.2938" stopColor="#E3E2FF" />
          <stop offset="0.6399" stopColor="#F1F0FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="-147.954"
          y1="160.466"
          x2="253.228"
          y2="160.466"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B8B8B8" />
          <stop offset="0.244792" stopColor="#E0E0E0" />
          <stop offset="0.734375" stopColor="#FEFEFE" />
          <stop offset="1" stopColor="#FEFEFE" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="-4.85532"
          y1="237.419"
          x2="39.6151"
          y2="237.419"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B8B8B8" />
          <stop offset="0.244792" stopColor="#E0E0E0" />
          <stop offset="0.734375" stopColor="#FEFEFE" />
          <stop offset="1" stopColor="#FEFEFE" />
        </linearGradient>
        <clipPath id="clip0">
          <rect
            width="3.45191"
            height="2.81406"
            transform="matrix(0.834986 -0.550271 0.0590564 0.998255 30.0962 208.576)"
            fill="white"
          />
        </clipPath>
        <clipPath id="clip1">
          <rect
            width="2.84949"
            height="2.84949"
            transform="matrix(0.834986 -0.550271 0.0590564 0.998255 30.4111 197.383)"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

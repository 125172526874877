import React from "react"

import Fintech from "../../../../images/MainPage/Blockwrapper/Experience/Icons/Money.svg"
import eCommerce from "../../../../images/MainPage/Blockwrapper/Experience/Icons/eCommerce.svg"
import WebRTC from "../../../../images/MainPage/Blockwrapper/Experience/Icons/WebRTC.svg"
import Edtech from "../../../../images/MainPage/Blockwrapper/Experience/Icons/Edtech.svg"
import TandS from "../../../../images/MainPage/Blockwrapper/Experience/Icons/TandS.svg"
import Medtech from "../../../../images/MainPage/Blockwrapper/Experience/Icons/Medtech.svg"
import Social from "../../../../images/MainPage/Blockwrapper/Experience/Icons/Social.svg"
import IoT from "../../../../images/MainPage/Blockwrapper/Experience/Icons/IoT.svg"
import ERP from "../../../../images/MainPage/Blockwrapper/Experience/Icons/Puzzles.svg"
import GatsbyLink from "/src/components/CommonComponents/GatsbyLink"

import "./Experience.scss"

const Experience = () => {
  return (
    <div className="exp-container">
      <div className="exp-center">
        <div className="exp-title">Industry Experience</div>
        <div className="exp-box">
          <div className="exp-item">
            <div className="exp-item-icon">
              <img className="exp-item-img" src={Medtech} alt="Medtech" />
              <div className="exp-text">
                <GatsbyLink to="/expertise/medical-app-development-services/">
                  MedTech
                </GatsbyLink>
              </div>
            </div>
          </div>
          <div className="exp-item">
            <div className="exp-item-icon">
              <img className="exp-item-img" src={Edtech} alt="Edtech" />
              <div className="exp-text">
                <GatsbyLink to="/cases/wikigrads/">
                  EdTech
                </GatsbyLink>
              </div>
            </div>
          </div>
          <div className="exp-item">
            <div className="exp-item-icon">
              <img className="exp-item-img" src={IoT} alt="IoT" />
              <div className="exp-text sm">
                <GatsbyLink to="https://iot.os-system.com/">
                  IoT
                </GatsbyLink>
              </div>
            </div>
          </div>
          <div className="exp-item">
            <div className="exp-item-icon">
              <img className="exp-item-img" src={WebRTC} alt="WebRTC" />
              <div className="exp-text">
                <GatsbyLink to="/expertise/rtc/">
                  WebRTC
                </GatsbyLink>
              </div>
            </div>
          </div>
          <div className="exp-item">
            <div className="exp-item-icon">
              <img
                className="exp-item-img"
                src={TandS}
                alt="Transport &amp shipping"
              />
              <div className="exp-text big">
                <GatsbyLink to="/expertise/">
                  Transport & shipping
                </GatsbyLink>
              </div>
            </div>
          </div>
          <div className="exp-item">
            <div className="exp-item-icon">
              <img className="exp-item-img" src={Fintech} alt="Fintech" />
              <div className="exp-text">
                <GatsbyLink to="/cases/service-doc/">
                  FinTech
                </GatsbyLink>
              </div>
            </div>
          </div>
          <div className="exp-item">
            <div className="exp-item-icon">
              <img className="exp-item-img" src={eCommerce} alt="e‑Commerce" />
              <div className="exp-text">
                <GatsbyLink to="/expertise/">
                  e‑Commerce
                </GatsbyLink>
              </div>
            </div>
          </div>
          <div className="exp-item">
            <div className="exp-item-icon">
              <img className="exp-item-img" src={Social} alt="Social Media" />
              <div className="exp-text">
                <GatsbyLink to="/cases/2cents/">
                  Social Media
                </GatsbyLink>
              </div>
            </div>
          </div>
          <div className="exp-item">
            <div className="exp-item-icon">
              <img className="exp-item-img" src={ERP} alt="ERP" />
              <div className="exp-text sm">
                <GatsbyLink to="/expertise/">
                  ERP
                </GatsbyLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Experience

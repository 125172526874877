import React from "react"

import { Link } from "gatsby"

import text from "../../../information/MainPage/dataForTeam.json"

import "./Team.scss"

const Team = () => {
  const { title, paragraph, button } = text
  return (
    <div className="team-container">
      <div className="team-center">
        <div className="team-wrapper">
          <div className="team-img-holder"></div>
          <div className="team-info">
            <div className="team-title">{title}</div>
            <div className="team-text">{paragraph}</div>
            <Link className="team-button" to="/contact/">
              {button}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Team

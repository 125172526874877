import React from "react"
import { Link } from "gatsby"
const GatsbyLink = ({ children, to, activeClassName, partiallyActive, ...other }) => {
  const internal = /^\/(?!\/)/.test(to)

  if (internal) {
    return (
      <Link
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </Link>
    )
  }
  return (
    <a href={to} target="_blank" rel="noreferrer" {...other}>
      {children}
    </a>
  )
}

export default GatsbyLink
import Clever from "../../images/MainPage/WhyUs/Clever.svg"
import Note from "../../images/MainPage/WhyUs/Note.svg"
import Partnership from "../../images/MainPage/WhyUs/Partnership.svg"
import Rocket from "../../images/MainPage/WhyUs/Rocket.svg"
import Smile from "../../images/MainPage/WhyUs/Smile.svg"
import Star from "../../images/MainPage/WhyUs/Star.svg"

const reasons = [
  {
    id: "1",
    img: Rocket,
    alt: "Rocket",
    header: "15+ years",
    description: "of development experience",
  },
  {
    id: "2",
    img: Partnership,
    alt: "Partnership",
    header: "Always in touch",
    description: "with our customers",
  },
  {
    id: "3",
    img: Star,
    alt: "Star",
    header: "Top rated",
    description: "company on UpWork & Clutch",
  },
  {
    id: "4",
    img: Note,
    alt: "Note",
    header: "High expertise",
    description: "in latest technologies",
  },
  {
    id: "5",
    img: Smile,
    alt: "Smile",
    header: "120+ satisfied customers",
    description: "from all over the world",
  },
  {
    id: "6",
    img: Clever,
    alt: "Clever",
    header: "40+ experienced",
    description: "software engineers",
  },
]

export default reasons

import React from "react"

import { Link } from "gatsby"

import "./HaveAnIdea.scss"

export default class HaveAnIdea extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="haveanidea-container">
        <div className="haveanidea-center">
          <div className="haveanidea-title">Already have an idea?</div>
          {this.props.width <= 950 && this.props.width > 810 ? (
            <div className="haveanidea-info">
              Click{" "}
              <span className="haveanidea-link-container">
                <Link to="/estimate/" className="haveanidea-link">
                  here
                </Link>
              </span>{" "}
              to discuss
              <br /> your project!
            </div>
          ) : this.props.width <= 810 ? (
            <div className="haveanidea-info">
              Click{" "}
              <span className="haveanidea-link-container">
                <Link to="/estimate/" className="haveanidea-link">
                  here
                </Link>
              </span>{" "}
              <br />
              to discuss your project!
            </div>
          ) : (
            <div className="haveanidea-info">
              Click{" "}
              <span className="haveanidea-link-container">
                <Link to="/estimate/" className="haveanidea-link">
                  here
                </Link>
              </span>{" "}
              to discuss your project!
            </div>
          )}
        </div>
      </div>
    )
  }
}

import React from "react"
import SEO from "../components/CommonComponents/SEO"
import Layout from "../components/CommonComponents/Layout"
import Main from "../components/MainPage/Main"
import WeBuild from "../components/MainPage/WeBuild"
import BlockWrapper from "../components/MainPage/BlockWrapper"
import WhyUs from "../components/MainPage/WhyUs"
import Team from "../components/MainPage/Team"
import Cases from "../components/CommonComponents/Cases"
// import projects from "../information/MainPage/dataForCarousel.json"
import { getCarouselData } from "../information/PortfolioProjects/dataForCases"
import RCHolder from "../components/MainPage/RCHolder/RCHolder"
import { mainUrl } from "../js/config"

const settings = {
  dots: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  arrows: true,
  responsive: [
    {
      breakpoint: 1021,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        className: "center",
      },
    },
  ],
}

export default function MainPage() {
  const projects = getCarouselData();
  return (
    <div>
      <SEO
        title="OS-System - Web and Mobile Application Development Company"
        description="OS-System - Full-cycle Software Development Company. We Provide Web & Mobile Application Development Services. Build your bright and innovative ideas with us."
        canonical={`${mainUrl}`}
      />
      <Layout showFormPoint={300}>
        <Main />
        <WeBuild />
        <BlockWrapper />
        <WhyUs />
        <Team />
        <Cases
          projects={projects}
          settings={settings}
          viewMoreBtn
          hashtags
          headerText="Cases"
        />
        <RCHolder />
      </Layout>
    </div>
  )
}

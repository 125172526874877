import React from "react"

import reasons from "../../../information/MainPage/dataForWhyUs"

import "./WhyUs.scss"

const WhyUs = () => {
  return (
    <div className="whyus-container">
      <div className="whyus-center">
        <div className="whyus-title">Why choose us?</div>
        <div className="whyus-info">
          Our mission is to provide the high-quality services with strong
          understanding of customer’s business needs as our own. We know how to
          evaluate your business requirements into valuable IT products
        </div>
        <div className="whyus-block">
          {reasons.map(item => {
            const { id, img, alt, header, description } = item
            return (
              <div className="whyus-item" key={id}>
                <img className="whyus-img" src={img} alt={alt} />
                <div className="whyus-text">
                  <span>{header}</span>
                  {description}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default WhyUs

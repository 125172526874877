import React from "react"

export default class CustomSlide extends React.Component {
  render() {
    const { review, i, ...props } = this.props
    return (
      <div className="review-item" {...props}>
        <div className="reviews-wrapper">
          <div className="reviews-first">
            <div className="blue-box" />
            <img
              className="reviews-image"
              src={require(`../../../../images/MainPage/RCHolder/Reviews/avatar.svg`)}
              alt={review.whoFirst}
            />
            <div className="reviews-first-name">{review.whoFirst}</div>
            <div className="reviews-second-name">{review.whoLast}</div>
          </div>
          <div className="reviews-second">
            <div className="reviews-info">{review.textHead}</div>
            <div className="reviews-description">{review.description}</div>
          </div>
        </div>
      </div>
    )
  }
}

import React from "react"

import { Link } from "gatsby"

import PC from "../../../images/MainPage/Main/Graphic/PC"

import AngularLogo from "../../../images/MainPage/Main/Graphic/Angular.png"
import VueLogo from "../../../images/MainPage/Main/Graphic/Vue.png"
import ReactLogo from "../../../images/MainPage/Main/Graphic/React.png"
import AWSLogo from "../../../images/MainPage/Main/Graphic/AWS.png"
import PythonLogo from "../../../images/MainPage/Main/Graphic/Python.png"

import Head from "../../../images/MainPage/Main/Graphic/Head.png"
import Body from "../../../images/MainPage/Main/Graphic/Body.png"
import Hand from "../../../images/MainPage/Main/Graphic/Hand.png"
import Shadow from "../../../images/MainPage/Main/Graphic/Shadow.png"

import "./Main.scss"

const Main = () => {
  return (
    <div className="main-wrapper">
      <div className="main-container">
        <div className="main-center">
          <div className="main-info">
            <h1 className="main-header">OS-System – IT Services Provider</h1>
            <div className="main-title">We build bright ideas</div>
            <div className="main-text">
              OS-System provides full-cycle development from initial concept to
              a live application. We design, build, and deploy innovative
              software and modern web applications.
            </div>
            <Link className="main-button" to="/estimate/">
              Estimate project
            </Link>
          </div>
          <div className="main-img">
            <div className="technologies-row" id="group_wrapper">
              <img src={AngularLogo} id="Angular" />
              <img src={VueLogo} id="Vue" />
              <img src={ReactLogo} id="React" />
              <img src={AWSLogo} id="Amazon" />
              <img src={PythonLogo} id="Python" />
            </div>
            <div className="main-robot">
              <img src={Head} id="Head" className="robot-head" />
              <img src={Body} id="Body" className="robot-body" />
              <img src={Hand} id="Hand" className="robot-hand" />
              <img src={Shadow} id="Shadow" className="robot-shadow" />
            </div>
            <PC />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Main

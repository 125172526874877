import {
  Arrow,
  Bug,
  Cloud,
  Computer,
  Laptop,
  Man,
  Mob,
  People,
  Rocket,
  Support,
  Ux,
} from "../../images/MainPage/Blockwrapper/ItServicesMob"

const dataForItServicesMob = [
  {
    id: "1",
    img: Computer,
    link: "/",
    title1: "Software ",
    title2: "Development",
  },
  {
    id: "2",
    img: Laptop,
    link: "/services/web/",
    title1: "Web Application ",
    title2: "Development",
  },
  {
    id: "3",
    img: Mob,
    link: "/services/mobile/",
    title1: "Mobile App ",
    title2: "Development",
  },
  {
    id: "4",
    img: Man,
    link: "/",
    title1: "Project",
    title2: "Manager",
  },
  {
    id: "5",
    img: Rocket,
    link: "/services/startups/",
    title1: "Startup",
    title2: "Consulting",
  },
  {
    id: "6",
    img: Cloud,
    link: "/expertise/cloud-solutions/",
    title1: "Dev ops &",
    title2: "Cloud Services",
  },
  {
    id: "7",
    img: People,
    link: "/services/dedicatedteam/",
    title1: "Development",
    title2: "Team",
  },
  {
    id: "8",
    img: Support,
    link: "/",
    title1: "Technical",
    title2: "Support",
  },
  {
    id: "9",
    img: Ux,
    link: "/expertise/uiuxdesign/",
    title1: "UI/UX Design",
    title2: "",
  },
  {
    id: "10",
    img: Bug,
    link: "/services/qa/",
    title1: "Software QA &",
    title2: "Testing",
  },
]

export default dataForItServicesMob

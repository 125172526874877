import React from "react"

import Fintech from "../../../../images/MainPage/Blockwrapper/Experience/Icons/Money.svg"
import eCommerce from "../../../../images/MainPage/Blockwrapper/Experience/Icons/eCommerce.svg"
import WebRTC from "../../../../images/MainPage/Blockwrapper/Experience/Icons/WebRTC.svg"
import Edtech from "../../../../images/MainPage/Blockwrapper/Experience/Icons/Edtech.svg"
import TandS from "../../../../images/MainPage/Blockwrapper/Experience/Icons/TandS.svg"
import Medtech from "../../../../images/MainPage/Blockwrapper/Experience/Icons/Medtech.svg"
import Social from "../../../../images/MainPage/Blockwrapper/Experience/Icons/Social.svg"
import IoT from "../../../../images/MainPage/Blockwrapper/Experience/Icons/IoT.svg"
import ERP from "../../../../images/MainPage/Blockwrapper/Experience/Icons/Puzzles.svg"
import GatsbyLink from "/src/components/CommonComponents/GatsbyLink"

import "./ExperienceMob.scss"

const ExperienceMob = () => {
  return (
    <div className="mobexperience-container">
      <div className="mobexperience-center">
        <div className="mobexperience-title">
          Industry
          <br />
          Experience
        </div>
        <div className="mobexperience-block">
          <div className="mobexperience-item">
            <GatsbyLink to="/expertise/medical-app-development-services/">
              <div className="mobexperience-icon">
                <img className="mobexperience-img" src={Medtech} alt="Icon" />
              </div>
              <div className="mobexperience-text">MedTech</div>
            </GatsbyLink>
          </div>
          <div className="mobexperience-item">
            <GatsbyLink to="/cases/wikigrads/">
              <div className="mobexperience-icon">
                <img className="mobexperience-img" src={Edtech} alt="Icon" />
              </div>
              <div className="mobexperience-text">EdTech</div>
            </GatsbyLink>
          </div>
          <div className="mobexperience-item">
            <GatsbyLink to="https://iot.os-system.com/">
              <div className="mobexperience-icon">
                <img className="mobexperience-img" src={IoT} alt="Icon" />
              </div>
              <div className="mobexperience-text">IoT</div>
            </GatsbyLink>
          </div>
          <div className="mobexperience-item">
            <GatsbyLink to="/expertise/rtc/">
              <div className="mobexperience-icon">
                <img className="mobexperience-img" src={WebRTC} alt="Icon" />
              </div>
              <div className="mobexperience-text">WebRTC</div>
            </GatsbyLink>
          </div>
          <div className="mobexperience-item">
            <GatsbyLink to="/expertise/">
              <div className="mobexperience-icon">
                <img className="mobexperience-img" src={TandS} alt="Icon" />
              </div>
              <div className="mobexperience-text">
                Transport &amp;
                <br />
                shipping
              </div>
            </GatsbyLink>
          </div>
          <div className="mobexperience-item">
            <GatsbyLink to="/cases/service-doc/">
              <div className="mobexperience-icon">
                <img className="mobexperience-img" src={Fintech} alt="Icon" />
              </div>
              <div className="mobexperience-text">FinTech</div>
            </GatsbyLink>
          </div>
          <div className="mobexperience-item">
            <GatsbyLink to="/expertise/">
              <div className="mobexperience-icon">
                <img className="mobexperience-img" src={eCommerce} alt="Icon" />
              </div>
              <div className="mobexperience-text">e‑Commerce</div>
            </GatsbyLink>
          </div>
          <div className="mobexperience-item">
            <GatsbyLink to="/cases/2cents/">
              <div className="mobexperience-icon">
                <img className="mobexperience-img" src={Social} alt="Icon" />
              </div>
              <div className="mobexperience-text">
                Social
                <br /> Media
              </div>
            </GatsbyLink>
          </div>
          <div className="mobexperience-item">
            <GatsbyLink to="/expertise/">
              <div className="mobexperience-icon">
                <img className="mobexperience-img" src={ERP} alt="Icon" />
              </div>
              <div className="mobexperience-text">ERP</div>
            </GatsbyLink>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExperienceMob

import React, { useState } from "react"

import { Link } from "gatsby"
import Slider from "react-slick"

import "./Cases.scss"

class Cases extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filter: "all",
      filteredData: this.props.projects,
      optionalClass: "cases-container",
      renderSlider: false,
    }
  }

  componentDidMount() {
    const { customClass } = this.props
    if (customClass) {
      this.setState({
        optionalClass: `cases-container ${customClass}`,
      })
    }
    this.setState({
      renderSlider: true,
    })
  }

  handleFilterChange = event => {
    this.setState(
      {
        filter: event.target.id,
      },
      () => this.filterData()
    )
  }

  filterData = () => {
    const { filter } = this.state
    const { projects } = this.props
    this.setState({
      filteredData: projects.filter((item) =>
        item.tags.some((item) =>
          item.toLowerCase() === filter.toLowerCase()
        )
      ),
    })
  }

  render() {
    const { filter, filteredData, renderSlider } = this.state
    const { settings, viewMoreBtn, hashtags, headerText } = this.props

    return (
      <div className={this.state.optionalClass}>
        <div className="cases-center">
          <div
            className={
              hashtags ? "cases-title" : "cases-title without-hashtags"
            }
          >
            <h2 className="cases-title">{headerText}</h2>
          </div>
          {hashtags && (
            <div className="cases-hashtags">
              <div
                className={
                  filter === "all"
                    ? "cases-hash-item active"
                    : "cases-hash-item"
                }
                id="all"
                onClick={this.handleFilterChange}
              >
                #all
              </div>
              <div
                className={
                  filter === "mobile"
                    ? "cases-hash-item active"
                    : "cases-hash-item"
                }
                id="mobile"
                onClick={this.handleFilterChange}
              >
                #mobile
              </div>
              <div
                className={
                  filter === "web"
                    ? "cases-hash-item active"
                    : "cases-hash-item"
                }
                id="web"
                onClick={this.handleFilterChange}
              >
                #web
              </div>
              <div
                className={
                  filter === "WebRTC"
                    ? "cases-hash-item active"
                    : "cases-hash-item"
                }
                id="WebRTC"
                onClick={this.handleFilterChange}
              >
                #WebRTC
              </div>
            </div>
          )}
          <div className="carousel-wrapper">
            {renderSlider && (
              <Slider {...settings}>
                {filteredData.map(project => {
                  const {
                    link,
                    id,
                    image,
                    rawImage,
                    alt,
                    title,
                    info,
                    tagBadges,
                  } = project
                  return (
                    <div className="cases-item" key={id}>
                      <Link to={link} className="cases-link">
                        <div className="cases-image-wrapper">
                          <img
                            className="cases-image"
                            src={image ? require(`../../../images/cases/casesCover/${image}.svg`) : (rawImage || '')}
                            alt={alt}
                          />
                        </div>
                        <div className="cases-info-container">
                          <div className="cases-name">{title}</div>
                          <div className="cases-info">{info}</div>
                        </div>
                        <div className="cases-badge-container">
                          {tagBadges.map(badge => {
                            const { id, text } = badge
                            return (
                              <div className="cases-badge" key={id}>
                                {text}
                              </div>
                            )
                          })}
                        </div>
                      </Link>
                    </div>
                  )
                })}
              </Slider>
            )}
          </div>
          {viewMoreBtn && (
            <Link to="/cases/" className="cases-wm-button">
              View More
            </Link>
          )}
        </div>
      </div>
    )
  }
}

export default Cases

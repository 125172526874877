import React from "react"

export default function MainPhone() {
  return (
    <svg
      className="phone-svg"
      width="202"
      height="475"
      viewBox="0 0 202 475"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="phone-border"
        d="M158.004 474.431C160.252 474.431 162.499 473.732 164.133 472.566L198.552 449.124C200.391 447.959 201.617 446.094 201.617 444.463C201.617 442.831 200.799 441.665 199.369 440.5L49.1172 344.264C46.257 342.4 41.3537 342.633 38.0849 344.731L3.66618 368.173C1.82747 369.338 0.601654 371.203 0.601654 372.835C0.601654 374.466 1.41887 375.632 2.84898 376.797L153.101 473.033C154.327 474.198 156.166 474.431 158.004 474.431ZM197.735 447.26L163.316 470.702C160.66 472.566 156.37 472.566 154.122 471.168L3.87053 374.932C3.25763 374.466 2.6447 374 2.6447 373.068C2.6447 372.135 3.46191 370.97 4.68773 370.271L39.1064 346.828C41.7623 344.964 46.0526 344.964 48.3 346.362L198.552 442.598C199.165 443.064 199.778 443.53 199.778 444.463C199.574 445.395 172.474 480.281 197.735 447.26Z"
        fill="#EFEFEF"
      />
      <path
        d="M147.064 434.808L109.563 256.587H108.676L19.1348 16.8679C28.0003 4.73021 35.0927 -6.39598 53.2669 4.32562L162.313 76.1401C172.508 82.8158 180.753 100.82 180.753 116.296L180.753 410.027C180.753 420.445 176.497 435.516 153.27 436.527C150.876 436.629 148.482 435.819 147.064 434.808Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M139.262 413.919L40.3971 347.171C31.1757 340.952 23.7275 324.265 23.7275 309.858L23.7275 37.0605C23.7275 22.6536 31.1757 16.0202 40.3971 22.3427L139.262 89.091C148.483 95.3098 155.932 111.997 155.932 126.404L155.932 399.201C156.02 413.504 148.483 420.138 139.262 413.919Z"
        fill="#373DD7"
      />
      <path
        d="M144.582 433.998L35.5359 362.184C25.3406 355.508 17.0957 337.504 17.0957 322.028L17.0957 28.2968C17.0957 12.8212 25.3406 5.74094 35.5359 12.4167L144.582 84.2312C154.777 90.9069 163.022 108.911 163.022 124.387L163.022 418.118C163.022 433.594 154.777 440.674 144.582 433.998Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M139.91 413.919L41.0456 347.171C31.8241 340.952 24.376 324.265 24.376 309.858L24.376 37.0605C24.376 22.6536 31.8241 16.0202 41.0456 22.3427L139.91 89.091C149.132 95.3098 156.58 111.997 156.58 126.404L156.58 399.201C156.669 413.504 149.132 420.138 139.91 413.919Z"
        fill="#A8CAE3"
      />
      <path
        className="phone-background"
        d="M139.262 255.972L40.3971 189.223C31.1757 183.005 23.7275 166.317 23.7275 151.911L23.7275 39.1132C23.7275 24.7064 31.1757 18.073 40.3971 24.3954L139.262 91.1437C148.483 97.3625 155.932 114.05 155.932 128.456V241.254C156.02 255.557 148.483 262.19 139.262 255.972Z"
        fill="#DDF1FF"
      />
      <g className="phone-buttons">
        <path
          className="phone-button-back"
          d="M80.037 332.457L54.963 315.845C52.791 314.402 51 310.511 51 307.145V287.996C51 284.63 52.791 283.1 54.963 284.543L80.037 301.155C82.209 302.598 84 306.489 84 309.855V329.004C84 332.37 82.209 333.9 80.037 332.457Z"
          fill="#7396FF"
        />
        <g className="phone-buttons-blue">
          <path
            d="M80.037 289.457L54.963 272.845C52.791 271.402 51 267.511 51 264.145V244.996C51 241.63 52.791 240.1 54.963 241.543L80.037 258.155C82.209 259.598 84 263.489 84 266.855V286.004C84 289.37 82.209 290.9 80.037 289.457Z"
            fill="white"
          />
          <path
            d="M115.157 313.457L90.843 296.845C88.7367 295.402 87 291.511 87 288.145V268.996C87 265.63 88.7367 264.1 90.843 265.543L115.157 282.155C117.263 283.598 119 287.489 119 290.855V310.004C119 313.37 117.263 314.9 115.157 313.457Z"
            fill="white"
          />
        </g>
        <g className="phone-buttons-white">
          <path
            d="M116.037 356.457L90.963 339.845C88.791 338.402 87 334.511 87 331.145V311.996C87 308.63 88.791 307.1 90.963 308.543L116.037 325.155C118.209 326.598 120 330.489 120 333.855V353.004C120 356.37 118.209 357.9 116.037 356.457Z"
            fill="white"
          />
          <path
            className="phone-button-fly"
            d="M80.037 332.457L54.963 315.845C52.791 314.402 51 310.511 51 307.145V287.996C51 284.63 52.791 283.1 54.963 284.543L80.037 301.155C82.209 302.598 84 306.489 84 309.855V329.004C84 332.37 82.209 333.9 80.037 332.457Z"
            fill="white"
          />
          <filter id="dropshadow" x="-2" y="-2" width="250" height="250">
            <feGaussianBlur stdDeviation="1" />
          </filter>
        </g>
      </g>
      <path
        className="phone-screen2"
        d="M130.91 228.217L48.0901 173.632C40.9157 168.892 35 156.108 35 145.047L35 82.1307C35 71.0701 40.9157 66.0425 48.0901 70.7828L130.91 125.368C138.084 130.108 144 142.892 144 153.953V216.869C144 227.93 138.084 232.957 130.91 228.217Z"
        fill="#7396FF"
      />
      <path
        className="phone-screen1"
        d="M130.91 228.217L48.0901 173.632C40.9157 168.892 35 156.108 35 145.047L35 82.1307C35 71.0701 40.9157 66.0425 48.0901 70.7828L130.91 125.368C138.084 130.108 144 142.892 144 153.953V216.869C144 227.93 138.084 232.957 130.91 228.217Z"
        fill="#DDF1FF"
      />
      <g className="phone-chart">
        <path
          className="phone-circle"
          d="M58.5975 123.851C56.5939 131.328 56.4526 140.403 58.1953 149.668C59.9381 158.932 63.4675 167.869 68.2363 175.091C73.0051 182.314 78.7467 187.419 84.5706 189.614C90.3945 191.81 95.9752 190.973 100.447 187.235C104.919 183.496 108.033 177.063 109.304 168.936C110.576 160.808 109.935 151.438 107.482 142.28C105.028 133.122 100.898 124.688 95.732 118.285C90.5664 111.882 84.654 107.869 78.9118 106.867L83.4633 148.717L58.5975 123.851Z"
          fill="#5578D7"
        />
        <path
          className="phone-pie"
          d="M79.1471 106.971C74.5658 106.17 70.2756 107.32 66.7081 110.303C63.1406 113.287 60.4217 117.999 58.825 123.967L83.6942 148.819L79.1471 106.971Z"
          fill="#B3D9F5"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="240.849"
          y1="218.322"
          x2="19.126"
          y2="218.523"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.169261" stopColor="#CCCCCC" />
          <stop offset="0.366844" stopColor="#F2F2F2" />
          <stop offset="1" stopColor="#F2F2F2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="285.617"
          y1="335.059"
          x2="-48.1592"
          y2="197.921"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DEDDFF" />
          <stop offset="0.2938" stopColor="#E3E2FF" />
          <stop offset="0.6399" stopColor="#F1F0FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  )
}
